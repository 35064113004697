import React, { Fragment } from 'react';
import { XTable } from 'erisxkit/client';
import { Header } from 'semantic-ui-react';
import SystemApplicationsGrid, { gridName } from './agGrid/SystemApplications/SystemApplicationsGrid';
import AgGridWrapper from '../common/table/agGrid/AgGridWrapper';
import SystemApplicationMetadataColDef from './agGrid/SystemApplications/SystemApplicationsMetadata';

const About = ({ data = [], metadata = [] }) => (
  <Fragment>
    <Header as="h1" dividing>System Applications</Header>
    <AgGridWrapper
      gridName={gridName}
      agGridComponent={<SystemApplicationsGrid data={data} colDefs={SystemApplicationMetadataColDef} />}
      reactTableComponent={
        <XTable
          title="about"
          noDataText="No applications were found."
          data={data}
          showPageSizeOptions={false}
          showPagination={false}
          pageSize={data && data.length}
          className="-striped -highlight"
          columns={metadata}
          filterable
          defaultFilterMethod={(filter, row) =>
          String(row[filter.id]).toUpperCase().includes(filter.value.toUpperCase())}
        />
      }
    />
  </Fragment>
);

export default About;
