import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

const ButtonRow = styled.div`
  display: flex;
  justify-content: end;
  height: 35px;
  margin: 15px 5px 10px 5px;
`;

export const useGridButtons = (
  gridRef: React.RefObject<AgGridReact> | undefined,
) => {
  return gridRef !== undefined ? (
    <ButtonRow>
      <Button
        icon="download"
        content="Export to CSV"
        onClick={() => {
          gridRef.current?.api.exportDataAsCsv();
        }}
      />
    </ButtonRow>
  ) : undefined;
};
