import React, { useEffect, useRef, useState } from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from '../../../common/table/agGrid/AgGridCommon';

export interface MarginRequirements {
  time: string;
  tradeDate: string;
  type: string;
  firmCode: string;
  accountCode: string;
  accountType: string;
  origin: string;
  marginRequirement: string;
  overrideComment: string;
  spanMarginRequirement: string;
  spanMarginRequirementOverride: string;
  guarantyFund: string;
  guarantyFundOverride: string;
  concentrationAddOn: string;
  concentrationAddOnOverride: string;
  stressAddOn: string;
  stressAddOnOverride: string;
  cover_2AddOn: string;
  cover_2AddOnOverride: string;
  discretionaryAddOn: string;
  discretionaryAddOnOverride: string;
}

interface MarginRequirementsFileGridProps {
  data: MarginRequirements[];
  colDefs: ColDef[];
  loading?: boolean;
  isJest?: boolean;
}

const defaultGridOptions: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true,
  defaultColDef: {
    resizable: true,
    sortable: true,
    filter: 'text',
    floatingFilter: true,
  },
};

export const gridName = 'margin-requirements';

const MarginRequirementsGrid: React.FC<MarginRequirementsFileGridProps> = ({
  data,
  colDefs,
  isJest,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<MarginRequirements[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated, gridButtons } =
    useAgGridCommon({
      isJest,
      gridName,
      gridRef,
    });

  useEffect(() => {
    setRowData(data);
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [data, initialGridSettings?.columnSizing]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <>
          {gridButtons}
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            gridOptions={defaultGridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
          />
        </>
      )}
    </div>
  );
};

export default MarginRequirementsGrid;
