import React, { useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from '../../../common/table/agGrid/AgGridCommon';
import FinanceAccountsGridMetadata from './FinanceAccountsGridMetadata';
import FinanceAccount from '../../../ts/models/FinanceAccount.model';
import { common } from '../../../utils/agGrid/options';

interface FinanceAccountsGridProps {
  data: FinanceAccount[];
  onSelectAccount: (accountId: string) => void;
  isJest?: boolean;
}

export const gridName = 'finance-accounts';

const FinanceAccountsGrid: React.FC<FinanceAccountsGridProps> = ({
  data,
  isJest,
  onSelectAccount,
}) => {
  const gridRef = useRef<AgGridReact>(null);

  const columnDefs = useMemo(
    () => FinanceAccountsGridMetadata(onSelectAccount),
    [onSelectAccount],
  );

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName,
  });

  useEffect(() => {
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [initialGridSettings?.columnSizing]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          gridOptions={common}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
        />
      )}
    </div>
  );
};

export default FinanceAccountsGrid;
