import React from 'react';
import { ColDef } from 'ag-grid-community';
import { Icon } from 'semantic-ui-react';
import get from 'lodash/get';
import {
  fundsDesignation as designations,
  SEG,
  MEMBER_PROPERTY,
  NON_SEG,
  COLLATERAL,
} from 'erisxkit/client';
import { ReconciliationDataType } from './ReconciliationGrid';

const ReconciliationMetadataColDef = (
  viewReconciliations: (id: string) => void,
): ColDef[] => [
  {
    field: 'firmCode',
    headerName: 'Firm Code',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'accountCode',
    headerName: 'Account Code',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'name',
    headerName: 'Account Name',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'subExchangeLabel',
    headerName: 'Subexchange',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'provider',
    headerName: 'Provider',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'providerAccountId',
    headerName: 'Provider Account Id',
    filter: 'agMultiColumnFilter',
  },
  {
    field: 'fundsDesignation',
    headerName: 'Funds Designation',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data = '' }) => {
      const value = get(data, 'fundsDesignation', '');
      switch (value) {
        case designations[SEG].value:
          return designations[SEG].text;
        case designations[MEMBER_PROPERTY].value:
          return designations[MEMBER_PROPERTY].text;
        case designations[NON_SEG].value:
          return designations[NON_SEG].text;
        case designations[COLLATERAL].value:
          return designations[COLLATERAL].text;
        default:
          return value;
      }
    },
  },
  {
    field: 'assetType',
    headerName: 'Asset Type',
    filter: 'agSetColumnFilter',
  },
  {
    field: 'amount',
    headerName: 'Amount',
    filter: 'agMultiColumnFilter',
    valueGetter: ({ data }) => {
      return get(data, 'balances', {})[
        get(data, 'assetType', '').toLowerCase()
      ];
    },
    cellClass: 'text-right mono',
  },
  {
    field: 'action',
    headerName: '',
    cellRenderer: ({ data }: { data?: ReconciliationDataType }) => (
      <span>
        {
          <Icon
            link
            title="View Reconciliation History"
            onClick={() => {
              viewReconciliations(data?.accountId || '');
            }}
            color="grey"
            name="arrow alternate circle right outline"
          />
        }
      </span>
    ),
    filter: false,
  },
];

export default ReconciliationMetadataColDef;
