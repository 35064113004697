import * as React from 'react';
import * as ReactDOM from 'react-dom';
// import 'semantic-ui-css/semantic.css';
// import 'react-table-6/react-table.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import AppContainer from './containers/AppContainer';

import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-063805}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Eris_Exchange,_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ErisX}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{ErisX}_need_to_be_licensed___{ErisX}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{18_August_2025}____[v3]_[01]_MTc1NTQ3MTYwMDAwMA==c9a5003ca4b15b682eff0a14c6a29687");

ReactDOM.render(<AppContainer />, document.getElementById('root'));
