import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showModal, hideModal } from 'erisxkit/client';
import isEmpty from 'lodash/isEmpty';
import XTable7 from '../../common/table/XTable7';
import { MARGIN_RATES } from '../../constants/modalTypes';
import { Button } from 'semantic-ui-react';
import { getCurrentUser } from '../../reducers/usersReducer';
import initialMarginApprovalsMetadata from '../../metadata/initialMarginRequirementsMetadata';
import {
  rejectPromiseCreator,
  REJECT,
} from '../../reducers/approvalReqsReducer';
import { approvePromiseCreator } from '../../actions/approvalReqsActions';
import {
  fetchFcmMarginRequirements,
  getFcmMarginRequirementList,
  getFcmMarginRequirements,
  getInitialMarginTradeDate,
} from '../../reducers/initialMarginReducer';
import { APPROVE } from '../../constants/actionTypes';
import AgGridWrapper from '../../common/table/agGrid/AgGridWrapper';
import MarginRequirementsGrid, {gridName} from '../../components/agGrid/InitialMargin/MarginRequirementsGrid';
import MarginRequirementsMetadataColDef from '../../components/agGrid/InitialMargin/MarginRequirementsMetadata';

const fetchData = () => {};

const InitialMarginRequirements = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(getCurrentUser);
  const tradeDate = useSelector(getInitialMarginTradeDate);
  const requirement = useSelector(getFcmMarginRequirements);
  const requirementList = useSelector(getFcmMarginRequirementList);
  
  const handleReject = async () => {
    try {
      await rejectPromiseCreator(
        { uuid: requirement?.approvalReqUuid },
        dispatch,
      );
      dispatch(hideModal(MARGIN_RATES));
      dispatch(fetchFcmMarginRequirements({ tradeDate }));
    } catch (error) {
      dispatch(hideModal(MARGIN_RATES));
    }
  };

  const handleApply = async () => {
    try {
      await approvePromiseCreator(requirement?.approvalReqUuid, dispatch);
      dispatch(hideModal(MARGIN_RATES));
      dispatch(fetchFcmMarginRequirements({ tradeDate }));
    } catch (error) {
      dispatch(hideModal(MARGIN_RATES));
    }
  };

  const showModalButtons = (action) => {
    dispatch(
      showModal(MARGIN_RATES, {
        closeOnEscape: true,
        hideModal: () => dispatch(hideModal(MARGIN_RATES)),
        updateStatus: action === 'Confirm' ? handleApply : handleReject,
        action,
        date: requirement?.tradeDate || '-',
        initiator: user,
        title:
          action === 'Confirm'
            ? 'Approve Initial Margin Override'
            : 'Reject Initial Margin Override',
        message:
          action === 'Confirm'
            ? 'You will approve the initial margin override'
            : 'You will reject the initial margin override',
        loadingSelector: action === 'Confirm' ? APPROVE : REJECT,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchFcmMarginRequirements({ tradeDate }));
  }, [tradeDate]);
  
  return (
    <Fragment>
      <div className="topHeader">
        <div className="marginRateMainTab">
          <p
            className="marginRateMainTabP"
            style={{ textTransform: 'capitalize' }}
          >
            <b className="marginRateText">Status:</b>{' '}
            {requirement?.state || '-'}
          </p>
        </div>
        <div className="alignRight">
          <Button
            className="add-button rejectBtn"
            id="rejectRequest"
            onClick={() => showModalButtons('Reject')}
            disabled={isEmpty(requirementList)}
          >
            Reject Request
          </Button>
          <Button
            className="add-button"
            id="applyRequest"
            onClick={() => showModalButtons('Confirm')}
            disabled={isEmpty(requirementList)}
          >
            Approve Request
          </Button>
        </div>
      </div>
      <AgGridWrapper
            gridName={gridName}
            agGridComponent={<MarginRequirementsGrid data={requirementList} isJest={!!props?.isJest}
            colDefs={MarginRequirementsMetadataColDef} />}
            reactTableComponent={
              <XTable7
                metadata={initialMarginApprovalsMetadata()}
                data={requirementList}
                fetchData={fetchData}
                loading={false}
                count={requirementList?.length || 0}
                localSortingAndFiltering
                flex
              />
            } 
        />
    </Fragment>
  );
};

InitialMarginRequirements.propTypes = {};

InitialMarginRequirements.defaultProps = {};

export default InitialMarginRequirements;
