import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';
import promotionsMetadata from '../../metadata/promotionsMetadata';
import {
  fetchApprovalReqs,
  selectApproval,
  approve,
} from '../../actions/approvalReqsActions';
import {
  getApprovalReqsCount,
  getApprovalReqsList,
} from '../../reducers/approvalReqsReducer';
import {
  filteredArrayToAttrValue,
  sortedArrayToAttrValue,
} from 'erisxkit/client';
import XTable7 from '../../common/table/XTable7';
import { isArray } from 'lodash';
import moment from 'moment';

const getDateFiveDaysAgo = () => moment().startOf('day').subtract(5, 'days');

const getDateThreeMonthsAgo = () =>
  moment().startOf('day').subtract(3, 'months');

// Returns from and to dates in unix (miliseconds)
const getInitialDates = () => [
  getDateThreeMonthsAgo().unix(),
  getDateFiveDaysAgo().unix(),
];

const PromotionsContainer = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getApprovalReqsList('pending')(state));
  const count = useSelector((state) => getApprovalReqsCount('pending')(state));

  const parseEpoch = (epochValue) => {
    if (!epochValue) return;
    return epochValue.toString().length > 10 ? epochValue / 1000 : epochValue;
  };

  const getDefaultFilters = (tableFilters) => {
    let filters = [
      {
        attr: 'sub_type',
        op: 'eq',
        value: 'promotion',
      },
    ];
    // Only add default time filtering if user isn't filtering manually by time
    if (!tableFilters.some((filter) => filter.id === 'time')) {
      filters = [
        ...filters,
        {
          attr: 'time',
          op: 'contain',
          value: getInitialDates(),
        },
      ];
    }
    return filters;
  };

  const onFetch = ({
    pageSize = 40,
    pageIndex = 0,
    filters = [],
    sortBy = [],
  }) => {
    let filter = getDefaultFilters(filters);

    // default sort by oldest applications first
    let sort = [
      {
        attr: 'time',
        value: 'desc',
      },
    ];

    if (filters.length !== 0) {
      filter = filter.concat(
        filteredArrayToAttrValue(
          filters.map((each) => ({
            ...each,
            op: 'contain',
          })),
        ),
      );
    }

    const filterWithMaxMin = filter.reduce((acc, curr) => {
      if (isArray(curr.value)) {
        return acc.concat([
          ...(curr.value[0]
            ? [
                {
                  ...curr,
                  op: 'gte',
                  value: `${moment.unix(parseEpoch(curr.value[0]))}`,
                },
              ]
            : []),
          ...(curr.value[1]
            ? [
                {
                  ...curr,
                  op: 'lte',
                  value: `${moment.unix(parseEpoch(curr.value[1]))}`,
                },
              ]
            : []),
        ]);
      }
      return acc.concat([curr]);
    }, []);

    if (sortBy.length !== 0) {
      sort = sortedArrayToAttrValue(sortBy);
    }

    dispatch(
      fetchApprovalReqs({
        limit: pageSize,
        offset: pageIndex * pageSize,
        page: pageIndex,
        filter: filterWithMaxMin,
        sort,
      }),
    );
  };

  const onApprove = (reqId) => {
    dispatch(selectApproval(reqId));
    dispatch(approve(reqId));
  };

  const onReject = (reqId) => {
    dispatch(selectApproval(reqId));
  };

  return (
    <div className="vert-flex">
      <Header dividing floated="left" as="h1">
        Promotion Reconciliations
      </Header>
      <XTable7
        title="promotion-table"
        metadata={promotionsMetadata(
          onApprove,
          onReject,
          onFetch,
          getInitialDates(),
        )}
        data={data}
        fetchData={onFetch}
        loading={false}
        count={count}
      />
    </div>
  );
};

export default PromotionsContainer;
