import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import XTab from '../../common/tabs/XTab';
import sections from './riskConfigSections';
import RiskConfigTable from './RiskConfigTable';
import adjustmentsMetadata from '../../metadata/riskConfigurationsMetadata/riskAdjustmentsMetadata';
import marginRatesMultipliersMetadata from '../../metadata/riskConfigurationsMetadata/marginRatesMultipliersMetadata';
import concentrationMetadata from '../../metadata/riskConfigurationsMetadata/concentrationAddOnMetadata';
import spanMetadata from '../../metadata/riskConfigurationsMetadata/spanMetadata';
import {
  CONCENTRATION_ADD_ON_TEMPLATE,
  MARGIN_RATES_MULTIPLIERS_TEMPLATE,
  RISK_ADJUSTMENTS_TEMPLATE,
  SPAN_INTER_SPREAD_TEMPLATE,
  SPAN_SPREAD_PRIORITY_TEMPLATE,
  MARGIN_PROFILES_TEMPLATE,
  RISK_PROFILES_TEMPLATE,
} from './newRowTemplates';
import { getSelectorAsOptions } from '../../selectors';
import { getFuturesProducts } from '../../reducers/futuresProductReducer';
import spanInterSpreadMetadata from '../../metadata/riskConfigurationsMetadata/spanInterSpreadMetadata';
import marginProfilesMetadata from '../../metadata/riskConfigurationsMetadata/marginProfilesMetadata';
import riskProfilesMetadata from '../../metadata/riskConfigurationsMetadata/riskProfilesMetadata';
import EnvPromise from '../../config/env';

const panes = (futuresProductsData, readRiskDataFromTcs = false) => {
  let marginParamsPanels = [
    {
      url: 'margin_rates_multipliers',
      menuItem: 'Margin Rates Multipliers',
      render: () => (
        <RiskConfigTable
          configSection={sections.MARGIN_RATES_MULTIPLIERS}
          metadata={marginRatesMultipliersMetadata(futuresProductsData)}
          editable
          template={MARGIN_RATES_MULTIPLIERS_TEMPLATE}
          key={sections.MARGIN_RATES_MULTIPLIERS}
        />
      ),
    },
    {
      url: 'risk_adjustments',
      menuItem: 'Risk Adjustments',
      render: () => (
        <RiskConfigTable
          configSection={sections.RISK_ADJUSTMENTS}
          metadata={adjustmentsMetadata(futuresProductsData)}
          editable
          template={RISK_ADJUSTMENTS_TEMPLATE}
          key={sections.RISK_ADJUSTMENTS}
        />
      ),
    },
    {
      url: 'concentration_add_on',
      menuItem: 'Concentration Add-On',
      render: () => (
        <RiskConfigTable
          configSection={sections.CONCENTRATION_ADD_ON}
          metadata={concentrationMetadata(futuresProductsData)}
          editable
          template={CONCENTRATION_ADD_ON_TEMPLATE}
          key={sections.CONCENTRATION_ADD_ON}
        />
      ),
    },
    {
      url: 'span',
      menuItem: 'SPAN Intra Spreads',
      render: () => (
        <RiskConfigTable
          configSection={sections.SPAN}
          metadata={spanMetadata(futuresProductsData)}
          editable={SPAN_SPREAD_PRIORITY_TEMPLATE}
          key={sections.SPAN}
        />
      ),
    },
    {
      url: 'span_inter_spread',
      menuItem: 'SPAN Inter Spreads',
      render: () => (
        <RiskConfigTable
          configSection={sections.SPAN_INTER_SPREAD}
          metadata={spanInterSpreadMetadata(futuresProductsData)}
          editable={SPAN_INTER_SPREAD_TEMPLATE}
          key={sections.SPAN_INTER_SPREAD}
        />
      ),
    },
  ];
  if (readRiskDataFromTcs) {
    marginParamsPanels.push(
      {
        url: 'margin_profiles',
        menuItem: 'Margin Profiles',
        render: () => (
          <RiskConfigTable
            configSection={sections.MARGIN_PROFILES}
            metadata={marginProfilesMetadata}
            editable
            template={MARGIN_PROFILES_TEMPLATE}
            key={sections.MARGIN_PROFILES}
          />
        ),
      },
      {
        url: 'risk_profiles',
        menuItem: 'Risk Profiles',
        render: () => (
          <RiskConfigTable
            configSection={sections.RISK_PROFILES}
            metadata={riskProfilesMetadata}
            editable
            template={RISK_PROFILES_TEMPLATE}
            key={sections.RISK_PROFILES}
          />
        ),
      },
    );
  }
  return marginParamsPanels;
};
const InitialMarginParams = () => {
  const futuresProductsData = useSelector(
    getSelectorAsOptions(getFuturesProducts, {
      key: 'symbol',
      value: 'symbol',
      text: 'symbol',
    }),
  );

  const [readRiskDataFromTcs, setReadRiskDataFromTcs] = useState(null);

  useEffect(() => {
    EnvPromise.then((e) =>
      setReadRiskDataFromTcs(e.readRiskDataFromTcs === 'true'),
    );
  }, []);

  return readRiskDataFromTcs === null ? null : (
    <XTab panes={panes(futuresProductsData, readRiskDataFromTcs)} />
  );
};

export default InitialMarginParams;
