import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Sidebar } from 'semantic-ui-react';
import FixedMenu from './FixedMenu';
import Navigation from './Navigation';
import ModalContainer from '../containers/ModalContainer';
import SidebarContainer from '../containers/SidebarContainer';
import InitialDataContainer from '../containers/InitialDataContainer';
import ErrorMessage from './ErrorMessage';
import EnvPromise from '../config/env';
import EnvBanner from './components/EnvBanner';

// Header, Navigation, Footer of app.
class AppLayout extends PureComponent {
  state = {
    envBanner: '',
    open: localStorage.getItem('nav-open') === 'true',
  };

  componentWillMount = () =>
    EnvPromise.then(({ envBanner = '' }) => this.setState({ envBanner }));

  render = () => (
    <article className="grid-container">
      <InitialDataContainer auth={this.props.auth}>
        <ErrorMessage />
        <ModalContainer />
        <Navigation />
        <div className="content-container">
          {isEmpty(this.state.envBanner) ? null : (
            <EnvBanner text={this.state.envBanner} />
          )}
          <FixedMenu auth={this.props.auth} />
          <div className="main-content">
            <Sidebar.Pushable>
              <SidebarContainer />
              <Sidebar.Pusher>{this.props.children}</Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        </div>
      </InitialDataContainer>
    </article>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
