"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.COLLATERAL = exports.OPERATING = exports.NON_SEG = exports.MEMBER_PROPERTY = exports.SEG = void 0;
var SEG = 0,
  MEMBER_PROPERTY = 1,
  NON_SEG = 2,
  OPERATING = 3,
  COLLATERAL = 4;
exports.COLLATERAL = COLLATERAL;
exports.OPERATING = OPERATING;
exports.NON_SEG = NON_SEG;
exports.MEMBER_PROPERTY = MEMBER_PROPERTY;
exports.SEG = SEG;
var _default = [{
  key: 'seg',
  text: 'Segregated (Futures)',
  value: 'S'
}, {
  key: 'memberProperty',
  text: 'Member Property (Futures)',
  value: 'P'
}, {
  key: 'nonSeg',
  text: 'Non-Segregated (Spot)',
  value: 'N'
}, {
  key: 'operating',
  text: 'Operating',
  value: 'O'
}, {
  key: 'collateral',
  text: 'Collateral (Spot)',
  value: 'C'
}];
exports.default = _default;