import React, { useEffect, useRef, useState } from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from '../../../common/table/agGrid/AgGridCommon';

interface MarginProfileMultipliers {
  marginProfile: string;
  product: string;
  initialMarginRateMultiplier: string;
  maintenanceMarginRateMultiplier: string;
  maxInitialMarginRate: string;
  minInitialMarginRate: string;
  maxMaintenanceMarginRate: string;
  minMaintenanceMarginRate: string;
  spreadsMaxInitialMarginRate: string;
  spreadsMaxMaintenanceMarginRate: string;
  spreadsMinInitialMarginRate: string;
  spreadsMinMaintenanceMarginRate: string;
  publicName: string;
  publishToWebsite: string;
}

interface MarginProfileMultipliersFileGridProps {
  data: MarginProfileMultipliers[];
  colDefs: ColDef[];
  loading?: boolean;
  isJest?: boolean;
}

const defaultGridOptions: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true,
  defaultColDef: {
    resizable: true,
    sortable: true,
    filter: 'text',
    floatingFilter: true,
  },
};

export const gridName = 'margin-profile-multipliers';

const MarginProfileMultipliersGrid: React.FC<
  MarginProfileMultipliersFileGridProps
> = ({ data, loading, colDefs, isJest }) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<MarginProfileMultipliers[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated, gridButtons } =
    useAgGridCommon({
      isJest,
      gridName,
      gridRef,
    });

  useEffect(() => {
    setRowData(data);
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [data, initialGridSettings?.columnSizing]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <>
          {gridButtons}
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            gridOptions={defaultGridOptions}
            onStateUpdated={onStateUpdated}
            initialState={initialGridSettings}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default MarginProfileMultipliersGrid;
