import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';
import { Modal, Button, Table, Icon, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { hideModal } from 'erisxkit/client';
import { fetchTaskLog, getTaskLog } from '../../reducers/taskLogReducer';
import { formatTimeLocalNumeric } from '../../utils/time';
import { DEBUG } from '../../constants/severity';
import OptionsToolbar from '../../common/components/OptionsToolbar';

const mapDispatchToProps = {
  hideModal,
  fetchTaskLog,
};

const mapStateToProps = (state, props) => ({
  taskLogs: getTaskLog(props.selectedTaskId)(state),
});

const renderMessage = (row) => {
  switch (row.type) {
    case 'trigger':
      return `Triggered by ${row.from} on ${row.host} with args: ${_.get(row, 'args', []).join(' ')}`;
    case 'start':
      return `${row.name} started on ${row.host}`;
    case 'log':
      return (
        <span className={`log-severity-${row.severity}`}>{row.message}</span>
      );
    case 'success':
      return (
        <span className="task-status-success">
          <Icon name="check circle" />
          Success
        </span>
      );
    case 'fail':
      return (
        <span className="log-severity-4">
          <Icon name="cross" />
          Failed {row.reason ? `:${row.reason}` : ''}
        </span>
      );
    default:
      return '';
  }
};

const renderBodyRow = (hideTimestamp) => (row, i) => {
  // skip rows with these event types
  if (['result', 'exception', 'progress'].includes(row.type)) {
    return '';
  }
  return {
    style: {
      fontSize: '10px',
      height: '21px',
      verticalAlign: 'top',
    },
    key: `row-${i}`,
    cells: [
      row.time && !hideTimestamp
        ? {
            content: formatTimeLocalNumeric(row.time),
            className: 'mono borderless',
            singleLine: true,
            collapsing: true,
          }
        : '',
      { content: renderMessage(row), className: 'mono pre borderless' },
    ],
  };
};

export const TaskDetails = ({
  hideTimestamp,
  loading,
  showDebug,
  taskLogs = [],
}) => (
  <Table
    className="extra-compact"
    basic="very"
    compact
    id="taskDetails"
    renderBodyRow={renderBodyRow(hideTimestamp)}
    tableData={taskLogs.filter(
      ({ severity, type }) =>
        showDebug || !(type === 'log' && severity === DEBUG),
    )}
  />
);

class TaskLog extends PureComponent {
  state = { showDebug: false };

  componentDidMount = () => {
    this.props.fetchTaskLog({ taskId: this.props.selectedTaskId });
  };

  render = () => {
    const { hideModal, loading } = this.props;
    return (
      <Fragment>
        <Modal.Header>
          {`${this.props.name} (${this.props.selectedTaskId})`}
        </Modal.Header>
        <Modal.Content>
          <OptionsToolbar
            groupProps={{
              compact: true,
              size: 'mini',
            }}
            toggleProps={{
              id: 'toggleDebug',
              onToggle: () => this.setState({ showDebug: !this.state.showDebug }),
              toggled: this.state.showDebug,
              text: 'Show debug information',
            }}
          />
          <Segment loading={loading}>
            <TaskDetails
              loading={loading}
              showDebug={this.state.showDebug}
              taskLogs={this.props.taskLogs}
            />
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary id="cancel" onClick={hideModal}>
            Close
          </Button>
        </Modal.Actions>
      </Fragment>
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskLog);
