import { call, takeLatest, put } from 'redux-saga/effects';
import { isNumber } from 'lodash';
import * as actionTypes from '../constants/actionTypes';
import api, * as urls from '../api';
import { fetchAbout } from '../actions/utilActions';
import { changeLogs } from '../reducers/systemAlertsReducer';
import {
  fetchTasks,
  fetchTaskLog,
  fetchTaskInfo,
} from '../reducers/taskLogReducer';
import { createSaga } from '../utils/createSaga';

function* fetchSystemAlerts(arg) {
  try {
    const systemAlerts = yield call(
      api.post,
      urls.SYSTEM_ALERTS_API_ENDPOINT,
      arg.payload,
    );
    yield put({
      type: actionTypes.SYSTEM_ALERTS_SUCCESS,
      payload: systemAlerts,
    });
  } catch (e) {
    yield put({ type: actionTypes.SYSTEM_ALERTS_FAILED, payload: e.response });
  }
}

function* fetchSystemAlertInfo() {
  try {
    const systemAlertInfo = yield call(
      api.post,
      urls.SYSTEM_ALERT_INFO_API_ENDPOINT,
    );
    yield put({
      type: actionTypes.SYSTEM_ALERT_INFO_SUCCESS,
      payload: systemAlertInfo,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SYSTEM_ALERT_INFO_FAILED,
      payload: e.response,
    });
  }
}

function* fetchExceptions(arg) {
  try {
    const exceptions = yield call(
      api.post,
      urls.EXCEPTIONS_API_ENDPOINT,
      arg.payload,
    );
    yield put({ type: actionTypes.EXCEPTIONS_SUCCESS, payload: exceptions });
  } catch (e) {
    yield put({ type: actionTypes.EXCEPTIONS_FAILED, payload: e.response });
  }
}

function* fetchExceptionInfo() {
  try {
    const exceptionInfo = yield call(
      api.post,
      urls.EXCEPTION_INFO_API_ENDPOINT,
    );
    yield put({
      type: actionTypes.EXCEPTION_INFO_SUCCESS,
      payload: exceptionInfo,
    });
  } catch (e) {
    yield put({ type: actionTypes.EXCEPTION_INFO_FAILED, payload: e.response });
  }
}

function* fetchChangeLogs(arg) {
  try {
    const changeLogsList = yield call(
      api.post,
      urls.CHANGE_LOGS_API_ENDPOINT,
      arg.payload,
    );
    yield put(changeLogs.success(changeLogsList));
  } catch (e) {
    yield put(changeLogs.failure(e.response));
  }
}

function* about() {
  try {
    yield put(fetchAbout.request());
    const versions = yield call(api.post, urls.ABOUT_API_ENDPOINT);
    yield put(fetchAbout.success(versions));
  } catch (e) {
    yield put(fetchAbout.failure(e.response));
  }
}

function* fetchTasksSaga(arg) {
  const currentPage = isNumber(arg.payload.page) ? arg.payload.page : 0;
  yield put(fetchTasks.request());
  try {
    const taskList = yield call(api.post, urls.TASKS_API_ENDPOINT, arg.payload);
    yield put(fetchTasks.success({ ...taskList, page: currentPage }));
  } catch (e) {
    yield put(fetchTasks.failure(e.response));
  }
}

function* fetchTaskLogSaga(arg) {
  yield put(fetchTaskLog.request());
  try {
    const taskLog = yield call(
      api.post,
      urls.TASK_LOG_API_ENDPOINT,
      arg.payload,
    );
    yield put(fetchTaskLog.success({ taskLog, taskId: arg.payload.taskId }));
  } catch (e) {
    yield put(fetchTaskLog.failure(e.response));
  }
}

export default function* watchSystemAlerts() {
  yield takeLatest(actionTypes.SYSTEM_ALERTS_REQUEST, fetchSystemAlerts);
  yield takeLatest(actionTypes.SYSTEM_ALERT_INFO_REQUEST, fetchSystemAlertInfo);
  yield takeLatest(actionTypes.EXCEPTIONS_REQUEST, fetchExceptions);
  yield takeLatest(actionTypes.EXCEPTION_INFO_REQUEST, fetchExceptionInfo);
  yield takeLatest(fetchAbout.TRIGGER, about);
  yield takeLatest(changeLogs.TRIGGER, fetchChangeLogs);
  yield takeLatest(fetchTasks.TRIGGER, fetchTasksSaga);
  yield takeLatest(fetchTaskLog.TRIGGER, fetchTaskLogSaga);
  yield takeLatest(
    fetchTaskInfo.TRIGGER,
    createSaga(fetchTaskInfo, actionTypes.TASK_INFO),
  );
}
