import { ColDef, ColGroupDef } from 'ag-grid-community';
import { BalanceDetail } from './BalanceDetailsGrid';
import { format } from 'erisxkit/client';
import { formatUSD } from '../../../utils/methods';
import { get } from 'lodash';
import moment from 'moment';
import compareNumbers from '../../../utils/agGrid/comparators/compareNumbers';

export type BalanceDetailRow = BalanceDetail & {
  _subRows?: BalanceDetail[];
};
export type BalanceDetailGridRow = Omit<BalanceDetailRow, '_subRows'> & {
  path: string[];
};

const columnDefs = (
  showCurrenexBalances: boolean,
  cdfEnabled = false,
): ColDef<BalanceDetailGridRow>[] => [
  {
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      data?.symbol ? `${data?.symbol.toUpperCase()} (${data?.fd})` : '',
    headerName: 'Asset (FD)',
    field: 'symbol'
  },
  {
    field: 'openingBalance',
    headerName: 'Opening',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.openingBalance) || 0,
    comparator: compareNumbers,
  },
  {
    field: 'spotMovement',
    headerName: 'Spot Movements',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      data?.spotMovement || '0',
    comparator: compareNumbers,
  },
  {
    field: 'exchangeFees',
    headerName: 'Ex Fees',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.exchangeFees) || 0,
    comparator: compareNumbers,
  },
  {
    field: 'clearingFees',
    headerName: 'Cl Fees',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.clearingFees) || 0,
    comparator: compareNumbers,
  },
  {
    field: 'otherFees',
    headerName: 'Other Fees',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.otherFees) || 0,
    comparator: compareNumbers,
  },
  {
    field: 'assetMovement',
    headerName: 'Asset Movements',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      data?.assetMovement || '0',
    comparator: compareNumbers,
  },
  {
    field: 'realizedPAndL',
    headerName: 'Realized P&L',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      data?.realizedPAndL || '0',
    comparator: compareNumbers,
  },
  {
    field: 'futuresDelivery',
    headerName: 'Futures Delivery',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      data?.futuresDelivery || '0',
    comparator: compareNumbers,
  },
  {
    field: 'variationMargin',
    headerName: 'Variation Margin',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.variationMargin) || 0,
    comparator: compareNumbers,
  },
  ...(cdfEnabled
    ? [
        {
          headerName: 'Funding Amount',
          cellClass: 'text-right mono',
          valueFormatter: ({ data }: { data?: BalanceDetail }) =>
            format(data?.fundingAmount) || '-',
          comparator: compareNumbers,
        },
      ]
    : []),
  {
    headerName: 'Closing',
    field: 'closingBalance',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.closingBalance) || 0,
    comparator: compareNumbers,
  },
  {
    headerName: 'Total Equity',
    field: 'totalEquity',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.totalEquity) || 0,
    comparator: compareNumbers,
  },
  {
    headerName: 'TME Balance',
    field: 'currenexBalance',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.currenexBalance) || 0,
  },
  {
    headerName: 'TME Available Balance',
    cellClass: 'text-right mono',
    hide: !showCurrenexBalances,
    field: 'purchasingPower',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.purchasingPower) || 0,
    comparator: compareNumbers,
  },
  {
    headerName: 'Initial Margin',
    cellClass: 'text-right mono',
    field: 'initialMargin',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.initialMargin) || 0,
    comparator: compareNumbers,
  },
  {
    headerName: 'Maintenance Margin',
    cellClass: 'text-right mono',
    field: 'maintenanceMargin',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.maintenanceMargin) || 0,
    comparator: compareNumbers,
  },
  {
    headerName: 'Pending',
    cellClass: 'text-right mono',
    field: 'pendingBalance',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.pendingBalance) || '',
    comparator: compareNumbers,
  },
  {
    headerName: 'Total USD value',
    cellClass: 'text-right mono',
    field: 'usdValue',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      data?.usdValue ? formatUSD(data?.usdValue) : '-',
    comparator: compareNumbers,
  },
  {
    headerName: 'Total Excess/Deficit',
    cellClass: 'text-right mono',
    field: 'totalExcessDeficit',
    valueFormatter: ({ data }: { data?: BalanceDetail }) =>
      format(data?.totalExcessDeficit) || 0,
    comparator: compareNumbers,
  },
  {
    headerName: 'Settlement Balance',
    cellClass: 'text-right mono',
    valueGetter: ({ data }: { data?: BalanceDetail }) => {
      const settlementBalance = get(data, [
        'settlementDetail',
        'settlementBalance',
      ]);
      return settlementBalance || '-';
    },
    comparator: compareNumbers,
  },
  {
    headerName: 'Settlement Time',
    cellClass: 'text-right mono',
    valueFormatter: ({ data }: { data?: BalanceDetail }) => {
      const settlementTime = get(data, ['settlementDetail', 'settlementTime']);
      return settlementTime
        ? moment(settlementTime).format('YYYY-MM-DD HH:mm')
        : '-';
    },
  },
];

export default columnDefs;
