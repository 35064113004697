"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getItemsPaged = exports.getItemIdsPaged = exports.getItemsCount = exports.getAllAccounts = exports.default = exports.createCurrentPage = exports.createPages = exports.createCount = exports.createById = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reselect = require("reselect");
var _arrayToObject = _interopRequireDefault(require("../../utils/lib/arrayToObject"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// Note: in helper methods that get slices of state, slices are flattened in order to support
//   deeper slices of state. Flattening a single-depth (i.e. string instead of array) slice will
//    function as normal.

var createById = function createById(routine, slice, id) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case routine.SUCCESS:
        return _objectSpread(_objectSpread({}, state), (0, _arrayToObject.default)(_lodash.default.get(action, _lodash.default.flatten(['payload', slice])), id));
      default:
        return state;
    }
  };
};
exports.createById = createById;
var createCount = function createCount(routine) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case routine.SUCCESS:
        return _lodash.default.get(action, ['payload', 'count'], '');
      default:
        return state;
    }
  };
};
exports.createCount = createCount;
var createPages = function createPages(routine, slice, id) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var pageIds;
    var page = _lodash.default.get(action, ['payload', 'page'], 'noPage');
    switch (action.type) {
      case routine.SUCCESS:
        pageIds = _lodash.default.get(action, _lodash.default.flatten(['payload', slice]), []).map(function (item) {
          return item[id];
        });
        return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2.default)({}, page, pageIds));
      default:
        return state;
    }
  };
};
exports.createPages = createPages;
var createCurrentPage = function createCurrentPage(routine) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var page = _lodash.default.get(action, ['payload', 'page']);
    switch (action.type) {
      case routine.SUCCESS:
        return _lodash.default.isNumber(page) ? page : state;
      default:
        return state;
    }
  };
};
exports.createCurrentPage = createCurrentPage;
var _default = function _default(routine, slice, id) {
  return (0, _redux.combineReducers)({
    byId: createById(routine, slice, id),
    count: createCount(routine),
    pages: createPages(routine, slice, id),
    currentPage: createCurrentPage(routine)
  });
}; //* Selectors */
exports.default = _default;
var getAllAccounts = function getAllAccounts(slice) {
  return function (state) {
    return _lodash.default.get(state, _lodash.default.flatten([slice, 'byId']), {});
  };
};
exports.getAllAccounts = getAllAccounts;
var getItemsCount = function getItemsCount(slice) {
  return function (state) {
    return _lodash.default.get(state, _lodash.default.flatten([slice, 'count']), '');
  };
};
exports.getItemsCount = getItemsCount;
var getItemIdsPaged = function getItemIdsPaged(slice) {
  return function (state) {
    var page = _lodash.default.get(state, _lodash.default.flatten([slice, 'currentPage']), 0);
    var pageIds = _lodash.default.get(state, _lodash.default.flatten([slice, 'pages']), {})[page];
    if (pageIds === undefined) {
      return [];
    }
    return pageIds;
  };
};
exports.getItemIdsPaged = getItemIdsPaged;
var getItemsPaged = function getItemsPaged(slice) {
  return (0, _reselect.createSelector)([getAllAccounts(slice), getItemIdsPaged(slice)], function (aById, aIds) {
    return aIds.map(function (o) {
      return aById[o];
    });
  });
};
exports.getItemsPaged = getItemsPaged;