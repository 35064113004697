export const RISK_ALERT_THRESHOLD_TEMPLATE = {
  riskProfile: '',
  alertType: '',
  symbol: '',
  threshold: '',
  severity: '',
  sendNotification: '',
  autoCloseAlert: '',
};

export const STRESS_ENGINE_TEMPLATE = {
  product: '',
  expiration_order: '',
  scenario: '',
  scenario_name: '',
  scenario_type: '',
  target_portfolio: '',
  shockValue: '',
};

export const DFRR_SIZING_PARAMS_TEMPLATE = {
  cover2ScaleFactor: '',
  antiprocyclicality: '',
  minimumDfrr: '',
  intramonthResizingThreshold: '',
  cboeMaxContribution: '',
  cboeIncrementalMarginCall: '',
  lookbackPeriod: '',
  movingAverageWindow: '',
};

export const GUARANTEE_FUND_ALLOC_TEMPLATE = {
  avgNetStressExposureWeight: '',
  avgGrossPortfolioNotionalExposureWeight: '',
  avgDailyGrossNotionalVolumeTradedWeight: '',
  minimumGuarantyFundRequirement: '',
};

export const MARGIN_RATES_MULTIPLIERS_TEMPLATE = {
  effectiveTradeDate: '',
  product: '',
  marginProfile: '',
  initialMarginRateMultiplier: '',
  maintenanceMarginRateMultiplier: '',
  discretionaryAddOn: '',
  maxInitialMarginRate: '',
  maxMaintenanceMarginRate: '',
  minInitialMarginRate: '',
  minMaintenanceMarginRate: '',
  spreadsMaxInitialMarginRate: '',
  spreadsMaxMaintenanceMarginRate: '',
  spreadsMinInitialMarginRate: '',
  spreadsMinMaintenanceMarginRate: '',
  publicName: '',
  publishToWebsite: '',
};

export const RISK_ADJUSTMENTS_TEMPLATE = {
  effectiveTradeDate: '',
  product: '',
  expirationFront: '',
  expirationBack: '',
  cashBasisAdjustment: '',
  calendarAdjustment: '',
  fundingAdjustment: '',
  spreadCharge: '',
};

export const CONCENTRATION_ADD_ON_TEMPLATE = {
  product: '',
  expiryIndex: '',
  initialContractsThreshold: '',
  initialAddOn: '',
  extraContractsThreshold: '',
  extraAddOn: '',
};

export const SPAN_SPREAD_PRIORITY_TEMPLATE = {
  spreadPriority: '',
  product: '',
  frontTenor: '',
  backTenor: '',
};

export const SPAN_INTER_SPREAD_TEMPLATE = {
  spreadPriority: '',
  frontProduct: '',
  frontTenor: '',
  frontQty: '',
  backProduct: '',
  backTenor: '',
  backQty: '',
  spreadCredit: '',
};

export const PRICING_ENGINE_PARAMS_TEMPLATE = {
  alpha: '',
  fundingRateInterval: '',
  referencePriceInterval: '',
  fundingRatePriceScale: '',
  maxBidAskSpread: '',
  maxDiffTheoVwapPrice: '',
  maxDiffReferencePreviousPrice: '',
};

export const LARGE_TRADER_MONITORING_TEMPLATE = {
  breachThreshold: '',
};

export const LARGE_TRADER_REPORT_FIRM_MAPPING_TEMPLATE = {
  largeTraderMappingId: '',
  largeTraderReportFirmId: '',
  tcsMemberId: '',
};

export const FINANCIAL_REVIEW_METRICS_TEMPLATE = {
  level: '',
  metric_name: '',
  month_lookback: '',
  threshold: '',
};

export const WINJAMMER_ACCOUNT_MAP_TEMPLATE = {
  wjFirmId: '',
  fiscalYearEnd: '',
  dsro: '',
  updatedAt: '',
  lastUpdatedBy: '',
};

export const NSE_THRESHOLD_CONFIG_TEMPLATE = {
  uuid: '',
  parameter: '',
  value: '',
};

export const FCM_GROUPS_TEMPLATE = {
  dfrrGroupName: '',
};

export const MARGIN_PROFILES_TEMPLATE = {
  name: '',
};

export const RISK_PROFILES_TEMPLATE = {
  name: '',
}