import React, { useEffect, useRef, useState } from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from '../../../common/table/agGrid/AgGridCommon';

export interface UBO {
  name: string;
  address: string;
  ownership: string;
  documentation: string;
  controller: string;
  documentsReceived: string;
  address1: string;
  address2: string;
  city: string;
  locationState: string;
  postalCode: string;
  country: string;
  percentageOwned: string;
}

interface UBOGridProps {
  data: UBO[];
  colDefs: ColDef[];
  isJest?: boolean;
}

const defaultGridOptions: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true,
  defaultColDef: {
    sortable: true,
    width: 200,
  },
};

export const gridName = 'UBO';

const UBOGrid: React.FC<UBOGridProps> = ({ data, colDefs, isJest }) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<UBO[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName,
  });

  useEffect(() => {
    setRowData(data);
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [data]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={defaultGridOptions}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
        />
      )}
    </div>
  );
};

export default UBOGrid;
