import React, { Fragment, useState, useEffect } from 'react';
import { Header, Segment, Button } from 'semantic-ui-react';
import XTable7 from '../common/table/XTable7';
import _ from 'lodash';
import { CONFIRM_TRANSFER } from '../constants/modalTypes';
import { showModal, hideModal } from 'erisxkit/client';
import { useSelector, useDispatch } from 'react-redux';
import {
  getClientTransfers,
  fetchClientTransfer,
  approveAllClientTransferPromiseCreator,
  rejectAllClientTransferPromiseCreator,
} from '../reducers/clientTransferReducer';
import clientTransferMedatada from '../metadata/clientTransferMetadata';
import BigNumber from 'bignumber.js';

const ClientTransfers = () => {
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState([]);
  const clientTransfer = useSelector(getClientTransfers);
  const approvalReqs = clientTransfer?.approvalReqs;

  const checkBoxObj = (chk, rowData) => ({
    name: chk.name,
    checked: chk.id,
    from: rowData.from,
    amount: rowData.amount,
    to: rowData.to,
    asset: rowData.asset,
    id: rowData.id,
  });
  const handleChange = (chk, rowData) => {
    const stateChk = checkBox.find((x) => x.name === chk.name);
    if (!stateChk) {
      // Add a new value
      const newValue = checkBoxObj(chk, rowData);
      setCheckBox([...checkBox, newValue]);
    } else {
      // Update the just the right one
      setCheckBox(
        _.map(checkBox, (c) =>
          c.name === chk.name ? checkBoxObj(chk, rowData) : c,
        ),
      );
    }
  };

  // checks if all approved checks are selected
  const areAllSelected = () =>
    checkBox.every((x) => x.checked.includes('approve')) &&
    checkBox.length === approvalReqs?.length;

  // select all the checks
  const selectAll = (e) => {
    // if are all selected then deselected all
    if (areAllSelected()) {
      setCheckBox([]);
    } else {
      const rows = [];
      approvalReqs.forEach((row) => {
        rows.push({
          name: `radioGroup${row.id}`,
          checked: `approve_${row.id}`,
          from: row.from,
          amount: row.amount,
          to: row.to,
          asset: row.asset,
          id: row.id,
        });
      });
      setCheckBox(rows);
    }
  };

  const fetchData = () => {};

  useEffect(() => {
    dispatch(fetchClientTransfer());
  }, []);

  const confirmOperation = async (data) => {
    // Separate the approved from the rejected
    const payload = {
      ids: checkBox
        .filter((x) => x.checked.includes('approve'))
        .map((x) => x.id),
      bankRef: data,
    };
    const rejectedPayload = {
      ids: checkBox
        .filter((x) => x.checked.includes('reject'))
        .map((x) => x.id),
    };

    if (payload.ids.length > 0) {
      try {
        await approveAllClientTransferPromiseCreator(payload, dispatch);
      } catch (error) {
        console.log('Error on approving client transfer');
      }
    }
    if (rejectedPayload.ids.length > 0) {
      try {
        await rejectAllClientTransferPromiseCreator(rejectedPayload, dispatch);
      } catch (error) {
        console.log('Error on rejecting client transfer');
      }
    }

    // clean variables
    bigAccFromNs = new BigNumber(0);
    bigAccFromMp = new BigNumber(0);
    setCheckBox([]);

    dispatch(fetchClientTransfer());
    dispatch(hideModal());
  };

  // Calls the modal
  const submit = () => {
    dispatch(
      showModal(CONFIRM_TRANSFER, {
        type: 'clearing',
        data: checkBox,
        confirm: (data) => confirmOperation(data),
        cancel: () => dispatch(hideModal()),
        accFromNs: bigAccFromNs.toNumber(),
        accFromMp: bigAccFromMp.toNumber(),
        approvedNet,
      }),
    );
  };

  // Sum of the selected ones
  let bigAccFromNs = new BigNumber(0);
  let bigAccFromMp = new BigNumber(0);
  const bigCero = new BigNumber(0);

  checkBox.forEach((chk) => {
    const from = chk.from?.toUpperCase();
    if (
      (from.includes('(NS)') || from.includes('(N)')) &&
      chk.checked.includes('approve')
    ) {
      bigAccFromNs = bigAccFromNs.plus(chk.amount);
    }
    if (
      (from.includes('(MP)') || from.includes('(P)')) &&
      chk.checked.includes('approve')
    ) {
      bigAccFromMp = bigAccFromMp.plus(chk.amount);
    }
  });

  bigAccFromNs = bigAccFromNs.eq(bigCero) ? bigCero : bigAccFromNs;
  bigAccFromMp = bigAccFromMp.eq(bigCero) ? bigCero : bigAccFromMp;

  const approvedNet = bigAccFromNs.minus(bigAccFromMp).abs().toFixed(2);

  return (
    <Fragment>
      <Header as="h1" content="Client Transfers Page" />
      <Segment>Last Transfer: {clientTransfer.lastTransfer}</Segment>
      <Segment>
        <Segment textAlign="center">
          Approved Net: ${approvedNet} To{' '}
          {bigAccFromNs.lt(bigAccFromMp) ? 'NS' : 'MP'}
        </Segment>
        <Segment.Group horizontal>
          <Segment>Approved Funds NS to MP: ${bigAccFromNs.toFixed(2)}</Segment>
          <Segment />
          <Segment>Approved Funds MP to NS: ${bigAccFromMp.toFixed(2)}</Segment>
        </Segment.Group>
      </Segment>
      <div className="text-right-marginBottom-10">
        <Button
          content={`${areAllSelected() && clientTransfer.count > 0 ? 'Deselect' : 'Approve'} All`}
          compact
          name="selectAll"
          onClick={selectAll}
          disabled={clientTransfer.count === 0}
        />
      </div>
      <XTable7
        metadata={clientTransferMedatada(checkBox, handleChange)}
        data={approvalReqs || []}
        fetchData={fetchData}
        showHeader
        showFilter={false}
        showPagination={false}
        height="60px"
      />
      <div className="text-center-marginTop-30">
        <Button
          content="Submit"
          name="submit"
          className="add-button"
          onClick={submit}
          size="medium"
          disabled={checkBox.length === 0}
        />
      </div>
    </Fragment>
  );
};

export default ClientTransfers;
