import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Segment, Button, Label, Select } from 'semantic-ui-react';
import XTable7 from '../common/table/XTable7';
import metadata from '../metadata/settlementServiceMetadata';

const fetchData = () => {};

const ConfirmRejectSettlement = ({ data, cancel, action, confirmAction }) => {
  const [selectedAccount, setSelectedAccount] = useState();
  return (
    <Fragment>
      <Modal.Header>Summary</Modal.Header>
      <Modal.Description>
        <Segment>
          <>
            <h4>Are you sure, you want to {action} the settlement?</h4>
            <XTable7
              metadata={metadata(null)}
              data={[data] || []}
              fetchData={fetchData}
              showHeader={true}
              showFilter={false}
              showPagination={false}
              height="60px"
            />
            {action === 'approve' && (
              <div className="margin-top-10">
                <Label>Choose Account to Approve on Behalf of</Label>
                <Select
                  onChange={(e, { value }) => setSelectedAccount(value)}
                  placeholder="Account"
                  options={[
                    {
                      key: data.fromAccountLabel,
                      text: data.fromAccountLabel,
                      value: data.fromAccountLabel,
                      description: data.fromAccountLabel,
                    },
                    {
                      key: data.toAccountLabel,
                      text: data.toAccountLabel,
                      value: data.toAccountLabel,
                      description: data.toAccountLabel,
                    },
                  ]}
                />
              </div>
            )}
          </>
        </Segment>
      </Modal.Description>
      <Modal.Content></Modal.Content>
      <Modal.Actions>
        <Button name="cancelAction" onClick={cancel} content="Cancel" />
        <Button
          name="confirmAction"
          disabled={selectedAccount || action === 'reject' ? false : true}
          onClick={() => confirmAction(selectedAccount)}
          primary
          content="Confirm"
        />
      </Modal.Actions>
    </Fragment>
  );
};

ConfirmRejectSettlement.propTypes = {
  data: 
    PropTypes.shape({
      name: PropTypes.string,
      checked: PropTypes.string,
      fromAccountLabel: PropTypes.string,
      amount: PropTypes.number,
      toAccountLabel: PropTypes.string,
      asset: PropTypes.string,
      id: PropTypes.string,
    }),
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  accFromNs: PropTypes.number,
  accFromMp: PropTypes.number,
};

ConfirmRejectSettlement.defaultProps = {
  data: {},
  confirm: () => {},
  cancel: () => {},
}

export default ConfirmRejectSettlement;
