import React from 'react';
import { XTable } from 'erisxkit/client';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReconciliationGrid, {
  gridName,
} from './agGrid/Reconciliation/ReconciliationGrid';
import AgGridWrapper from '../common/table/agGrid/AgGridWrapper';
import ReconciliationMetadataColDef from './agGrid/Reconciliation/ReconciliationMetadata';

const ReconciliationIndex = ({ data, metadata, viewReconciliations }) => (
  <div className="vert-flex">
    <Header dividing floated="left" as="h1">
      Select Account to Reconcile
    </Header>
    <AgGridWrapper
      gridName={gridName}
      agGridComponent={
        <ReconciliationGrid
          data={data}
          viewReconciliations={viewReconciliations}
          colDefs={ReconciliationMetadataColDef(viewReconciliations)}
        />
      }
      reactTableComponent={
        <XTable
          title="reconciliationIndex"
          noDataText="No accounts were found."
          minRows={20}
          defaultPageSize={20}
          data={data}
          showPageSizeOptions={false}
          showPagination={true}
          className="-striped -highlight"
          columns={metadata}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toUpperCase()
              .includes(filter.value.toUpperCase())
          }
          getTrProps={(state, rowInfo) => ({
            onClick: (e) => {
              viewReconciliations(rowInfo.original.accountId);
            },
            className: 'pointer',
          })}
        />
      }
    />
  </div>
);

ReconciliationIndex.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ReconciliationIndex;
