"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  key: 'view_market_data',
  label: 'Market Data'
}, {
  key: 'submit_order',
  label: 'Trading'
}, {
  key: 'read_clearing_api',
  label: 'Clearing (ReadOnly)'
}, {
  key: 'write_clearing_api',
  label: 'Funding'
}, {
  key: 'onboard_member_user',
  label: 'Onboarding'
}, {
  key: 'settlement_service',
  label: 'Settlement Service'
}];
exports.default = _default;