import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Select } from 'semantic-ui-react';

const firstOption = [
  {
    key: null,
    text: 'Show All',
    value: null,
    description: null,
  },
];

const SearchSelect = ({
  additionalFilters,
  searchAttr,
  searchOp,
  fetch,
  showAllOption,
  options,
  input,
  loading,
  className,
  multiple,
  name,
  placeholder,
  searchText,
}) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChangeDebounceFn = (searchQuery) => {
    if (fetch) {
      dispatch(
        fetch({
          filter: [
            { attr: searchAttr, op: searchOp, value: searchQuery },
            ...additionalFilters,
          ],
          limit: 10,
        }),
      );
    }
  };

  const searchChangeDebounceFn = useCallback(
    _.debounce(handleSearchChangeDebounceFn, 500),
    [],
  );

  const handleSearchChange = (e, { searchQuery }) => {
    searchChangeDebounceFn(searchQuery);
    setSearchQuery({ searchQuery });
  };

  return (
    <Form.Field
      control={Select}
      search
      selection
      options={showAllOption ? [...firstOption, ...options] : options}
      onChange={(e, { value }) => _.get(input, ['onChange'], () => {})(value)}
      defaultValue={_.get(input, ['value'])}
      value={_.get(input, ['value'])}
      onSearchChange={handleSearchChange}
      loading={loading}
      noResultsMessage={searchQuery ? '' : searchText}
      additionalFilters={additionalFilters}
      className={className}
      input={input}
      multiple={multiple}
      name={name}
      placeholder={placeholder}
      showAllOption={showAllOption}
    />
  );
};

SearchSelect.propTypes = {
  additionalFilters: PropTypes.arrayOf(
    PropTypes.shape({
      attr: PropTypes.string,
      op: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  fetch: PropTypes.func.isRequired,
  searchAttr: PropTypes.string,
  searchOp: PropTypes.string,
  showAllOption: PropTypes.bool,
  className: PropTypes.string,
  searchText: PropTypes.string,
};

SearchSelect.defaultProps = {
  additionalFilters: [],
  name: 'userId',
  options: [],
  placeholder: 'Enter value to search',
  multiple: false,
  showAllOption: false,
  searchAttr: 'email',
  searchOp: 'contain',
  className: '',
  searchText: 'Search',
};

export default SearchSelect;
