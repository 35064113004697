import { GridState, StateUpdatedEvent } from 'ag-grid-community';
import { setGridSettings, useAgLocalStorage } from './useAgLocalStorage';
import { useCallback, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useGridButtons } from './useGridButtons';

interface UseAgGridCommonParams {
  gridName: string;
  isJest?: boolean;
  gridRef?: React.RefObject<AgGridReact> | undefined;
}

export const useAgGridCommon = ({
  gridName,
  isJest,
  gridRef = undefined,
}: UseAgGridCommonParams) => {
  const [gridVisible, setGridVisible] = useState(true);
  const initialGridSettings: GridState = useAgLocalStorage(gridName);
  const gridButtons = useGridButtons(gridRef);

  // Used to force a re-render if initialGridSettings has changed, b/c AgGridReact
  // initialState prop is only read on initialization
  const reloadGrid = useCallback(() => {
    setGridVisible(false);
    setTimeout(() => {
      setGridVisible(true);
    });
  }, []);

  useEffect(() => {
    reloadGrid();
  }, [initialGridSettings, reloadGrid]);

  const onStateUpdated = (event: StateUpdatedEvent) => {
    setGridSettings(gridName, event.state);
  };

  return {
    gridVisible: gridVisible || isJest,
    initialGridSettings,
    onStateUpdated,
    gridButtons,
  };
};
