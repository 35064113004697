"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFundsDesignationTextByValue = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _constants = require("../../constants");
var getFundsDesignationTextByValue = function getFundsDesignationTextByValue(value) {
  return _lodash.default.chain(_constants.fundsDesignation).filter(function (fd) {
    return fd.value === value;
  }).get(['0', 'text']).value();
};
exports.getFundsDesignationTextByValue = getFundsDesignationTextByValue;