const MarginProfileMultipliersMetadataColDef = [
  {
    field: 'marginProfile',
    headerName: 'Profile Name',
    filter: "agMultiColumnFilter" 
  },
  {
    field: 'product',
    headerName: 'Product',
    filter: "agMultiColumnFilter" 
  },
  {
    field: 'initialMarginRateMultiplier',
    headerName: 'Initial Multiplier',
    filter: "agMultiColumnFilter" 
  },
  {
    field: 'maintenanceMarginRateMultiplier',
    headerName: 'Maintenance Multiplier',
    filter: "agMultiColumnFilter" 
  },
  {
    field: 'maxInitialMarginRate',
    headerName: 'Max Initial Rate',
    filter: "agMultiColumnFilter" 
  },
  {
    headerName: 'Min Initial Rate',
    field: 'minInitialMarginRate',
    filter: "agMultiColumnFilter" 
  },
  {
    headerName: 'Max Maintenance Rate',
    field: 'maxMaintenanceMarginRate',
    filter: "agMultiColumnFilter" 
  },
  {
    headerName: 'Min Maintenance Rate',
    field: 'minMaintenanceMarginRate',
    filter: "agMultiColumnFilter" 
  },
  {
    headerName: 'Spreads Max Initial Rate',
    field: 'spreadsMaxInitialMarginRate',
    filter: "agMultiColumnFilter" 
  },
  {
    headerName: 'Spreads Max Maintenance Rate',
    field: 'spreadsMaxMaintenanceMarginRate',
    filter: "agMultiColumnFilter" 
  },
  {
    headerName: 'Spreads Min Initial Rate',
    field: 'spreadsMinInitialMarginRate',
    filter: "agMultiColumnFilter" 
  },
  {
    headerName: 'Spreads Min Maintenance Rate',
    field: 'spreadsMinMaintenanceMarginRate',
    filter: "agMultiColumnFilter" 
  },
  {
    headerName: 'Public Name',
    field: 'publicName',
    filter: "agMultiColumnFilter" 
  },
  {
    headerName: 'Publish to Website',
    field: 'publishToWebsite',
    filter: "agMultiColumnFilter" 
  },
];

export default MarginProfileMultipliersMetadataColDef;