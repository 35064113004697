import React, { useEffect, useRef, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from '../../../common/table/agGrid/AgGridCommon';
import styled from 'styled-components';
import { common } from '../../../utils/agGrid/options';

interface CoreFile {
  taskId: string;
  state: string;
  value: string;
}

interface ExportCoreFileGridProps {
  data: CoreFile[];
  colDefs: ColDef[];
  isJest?: boolean;
}

const StyledAgGridContainer = styled.div`
  height: 350px;
  ag-root-wrapper {
    height: 100% !important;
  }
`;

export const gridName = 'export-core-file';

const ExportCoreFileGrid: React.FC<ExportCoreFileGridProps> = ({
  data,
  colDefs,
  isJest,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<CoreFile[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName,
  });

  useEffect(() => {
    setRowData(data);
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [data]);

  return (
    <StyledAgGridContainer className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={common}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
        />
      )}
    </StyledAgGridContainer>
  );
};

export default ExportCoreFileGrid;
