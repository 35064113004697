"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = modalReducer;
exports.hideModal = exports.showModal = exports.getAllArgs = exports.getAllModalTypes = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _lodash = _interopRequireDefault(require("lodash"));
var _reselect = require("reselect");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//* Modal Action Types */
var SHOW_MODAL = 'SHOW_MODAL';
var HIDE_MODAL = 'HIDE_MODAL';

/** Initial State */
var initialModalState = {
  modalTypes: [],
  args: {}
};

/** Modal reducer */
function modalReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialModalState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalTypes: [].concat((0, _toConsumableArray2.default)(state.modalTypes), [action.modalType]),
        args: _objectSpread(_objectSpread({}, state.args), {}, (0, _defineProperty2.default)({}, action.modalType, action.args))
      };
    case HIDE_MODAL:
      return {
        modalTypes: _lodash.default.slice(state.modalTypes, 0, -1),
        args: _lodash.default.omit(state.args, action.modalType)
      };
    default:
      return state;
  }
}

//* Selectors */
var getAllModalTypes = function getAllModalTypes(state) {
  return _lodash.default.get(state, ['ui', 'modal', 'modalTypes'], []);
};
exports.getAllModalTypes = getAllModalTypes;
var getAllArgs = function getAllArgs(state) {
  return _lodash.default.get(state, ['ui', 'modal', 'args'], {});
};
/**
 * Action creator to load a modal based on a modalType.
 * @param {String} modalType - The name of modal obtained from /constants/modalTypes.
 */
exports.getAllArgs = getAllArgs;
var showModal = function showModal(modalType, args) {
  return {
    type: SHOW_MODAL,
    modalType: modalType,
    args: args
  };
};

/**
 * Action generator to hide a modal.
 *  * @param {String} modalType - The name of modal obtained from /constants/modalTypes.
 */
exports.showModal = showModal;
var hideModal = function hideModal(modalType) {
  return {
    type: HIDE_MODAL,
    modalType: modalType
  };
};
exports.hideModal = hideModal;