"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _lodash = require("lodash");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Number formatting utility
 * @param {string} val: numeric value to convert. must be a string
 * @param {object} args: (optional)
 *  locale: Country and language code, e.g. 'en-US', 'de-DE', 'ja'
 *  type: ['crypto' | 'fiat']. Defaults to 'crypto'.
 *  currency: 'USD', 'EUR', 'GBP', 'JPY', etc. necessary for type: 'fiat'.
 */

var formatMantissa = function formatMantissa() {
  var mantissa = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var truncate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var isFiat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var str = mantissa;

  // take first two character slice if truncation is specified
  if (truncate > 0) {
    str = "".concat(mantissa.slice(0, truncate));
  }

  // regardless of truncation, we want at least two decimal places for fiat.
  // concat with 00 and take the resulting slice.
  if (str.length <= 1 && isFiat) {
    return str.concat('00').slice(0, 2);
  }
  return str;
};

// regex to find fractional component
var fractional = /\.([0-9]+)/;
var formatFiat = function formatFiat(_ref) {
  var str = _ref.str,
    locale = _ref.locale,
    currency = _ref.currency,
    _ref$truncate = _ref.truncate,
    truncate = _ref$truncate === void 0 ? 0 : _ref$truncate;
  var match = str.match(fractional);

  // if a mantissa and decimal point exist, remove them from formatting
  //  and concat them back in to preserve precision.
  // we should be able to handle fiat into the quadrillions with arbitrary-
  //  length mantissas.
  var _ref2 = match || [],
    _ref3 = (0, _slicedToArray2.default)(_ref2, 2),
    mantissa = _ref3[1];
  return "".concat(Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency.toUpperCase(),
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(!(0, _lodash.isEmpty)(match) ? Math.trunc(str) : str), ".").concat(formatMantissa(mantissa, truncate, true));
};
var formatCrypto = function formatCrypto(_ref4) {
  var str = _ref4.str,
    _ref4$truncate = _ref4.truncate,
    truncate = _ref4$truncate === void 0 ? 0 : _ref4$truncate;
  var match = str.match(fractional);
  var _ref5 = match || [],
    _ref6 = (0, _slicedToArray2.default)(_ref5, 2),
    mantissa = _ref6[1];
  var characteristic = (0, _bignumber.default)(Math.trunc(str));
  var prefix = '';
  // negative has to be manually put in to numbers
  if (characteristic.isNegative()) {
    prefix = '-';
  }
  // taking the absolute value so the negative gets stripped out
  return "".concat(prefix).concat(characteristic.abs().toFormat()).concat(!(0, _lodash.isEmpty)(match) ? '.' : '').concat(formatMantissa(mantissa, truncate));
};
var format = function format(val) {
  var _ref7 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var locale = _ref7.locale,
    type = _ref7.type,
    _ref7$currency = _ref7.currency,
    currency = _ref7$currency === void 0 ? '' : _ref7$currency,
    rest = (0, _objectWithoutProperties2.default)(_ref7, ["locale", "type", "currency"]);
  var value = (0, _bignumber.default)(val); // use Big to avoid floating point arithmetic errors

  // If none is provided, default to hardcoded US locale since other locales create issues with formatting
  if (!locale) {
    locale = 'en-US';
  }

  // return original value as string if cannot parse
  if ((0, _lodash.isEmpty)(val) || (0, _lodash.get)(value, 'c') === null) {
    var stringified = "".concat(val);

    // undefined and null should give empty strings
    if ((0, _lodash.isEmpty)(val)) {
      return '';
    }
    return stringified;
  }
  switch (type) {
    // localize currency, format with given type and locale
    case 'currency':
    case 'fiat':
      return formatFiat(_objectSpread({
        str: value.toFixed(),
        locale: locale,
        currency: currency
      }, rest));
    // Prettify long crypto balances, force 8 decimal places
    case 'crypto':
    default:
      return formatCrypto(_objectSpread({
        str: value.toFixed()
      }, rest));
    // In the case of many, many digits, don't show scientific notation
  }
};
exports.format = format;