"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = loadingReducer;
exports.createLoadingSelector = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** Reducer for all loading concerns within the UI */
function loadingReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type;
  var matches = /(.*)[_/](REQUEST|SUCCESS|FAILED|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILED actions, so we ignore them
  if (!matches) return state;
  var _matches = (0, _slicedToArray2.default)(matches, 3),
    requestName = _matches[1],
    requestState = _matches[2];
  return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2.default)({}, requestName, requestState === 'REQUEST'));
}
var createLoadingSelector = function createLoadingSelector(actions) {
  return function (state) {
    return (
      // returns true only when all actions is not loading
      (0, _lodash.default)(actions).some(function (action) {
        return _lodash.default.get(state, "api.loading.".concat(action));
      })
    );
  };
};
exports.createLoadingSelector = createLoadingSelector;