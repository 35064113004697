import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import {
  Grid,
  Header,
  Icon,
  Message,
  Progress,
  Segment,
  Statistic,
  Table,
} from 'semantic-ui-react';
import { format, fundsDesignation, NON_SEG } from 'erisxkit/client';
import PropTypes from 'prop-types';
import Big from 'bignumber.js';
import {
  calculateNotional,
  formatNegative,
  formatZero,
  getFundsDesignationTextByValue,
  mapAssetTypeToSymbol,
} from '../utils/methods';
import { COMBINED } from '../containers/FundsSegregationBalancesContainer';
import OptionsToolbar from '../common/components/OptionsToolbar';

// Add formatting utils as lodash mixins for chaining
_.mixin({ calculateNotional });
_.mixin({ format });
_.mixin({ formatNegative });
_.mixin({ formatZero });

export const renderSymbol = ({
  homeAssetType,
  homeAssetTypeSymbol,
  assetType,
  useNotional,
}) =>
  useNotional ||
  homeAssetType === assetType ||
  _.isEmpty(assetType) ||
  assetType === COMBINED
    ? homeAssetTypeSymbol
    : assetType;

export const balanceCellClass = (balance) =>
  Math.sign(balance) === -1 ? ' negative-value' : '';

const status = {
  NORMAL: 0,
  WARNING: 1,
  CRITICAL: 2,
};

const getBufferStatus = ({
  percentBufferUsed,
  criticalThreshold,
  warningThreshold,
}) => {
  if (Big(percentBufferUsed).gte(Big(criticalThreshold))) {
    return status.CRITICAL;
  } else if (Big(percentBufferUsed).gte(Big(warningThreshold))) {
    return status.WARNING;
  }
  return status.NORMAL;
};

export const renderBalanceWithAssetType = ({
  homeAssetType,
  homeAssetTypeSymbol,
  assetType,
  useNotional,
  closingPx,
  balance,
}) => [
  <span className={`pull-left${balanceCellClass(balance)}`}>
    {renderSymbol({
      homeAssetType,
      homeAssetTypeSymbol,
      assetType,
      useNotional,
    })}
  </span>,
  <span className={balanceCellClass(balance)}>
    {balance !== '—'
      ? _(balance)
          .calculateNotional(closingPx, useNotional)
          .format()
          .formatZero()
          .formatNegative()
          .value()
      : balance}
  </span>,
];

export const renderRow = ({
  balancesByAssetType = {},
  balanceName,
  description,
  fd,
  ...rest
}) => (
  <Table.Row>
    <Table.Cell>{description}</Table.Cell>
    {Object.values(balancesByAssetType).map(({ assetType, ...details }, i) => (
      <Table.Cell
        textAlign="right"
        className="mono border-right no-wrap"
        key={`${balanceName}-${i}}`}
      >
        {renderBalanceWithAssetType({
          assetType,
          balance: _(_.get(details, [fd, balanceName], 0))
            .formatZero()
            .value(),
          closingPx: _.get(details, [fd, 'closingPx'], ''),
          ...rest,
        })}
      </Table.Cell>
    ))}
  </Table.Row>
);

const renderRows = (rowProps) =>
  fundsDesignation.map(({ text, value }) => (
    <Fragment>
      {[
        { balanceName: 'available', description: `Total Amount in ${text}` },
        {
          balanceName: 'required',
          description: `Amount Required to be ${text}`,
        },
        {
          balanceName: 'excessDeficiency',
          description: `Excess (deficiency) Funds in ${text}`,
        },
      ].map(({ balanceName, description }) =>
        renderRow({
          balanceName,
          description,
          fd: value,
          ...rowProps,
        }),
      )}
    </Fragment>
  ));

export const BalancesTable = ({
  title,
  assetTypes,
  balancesByAssetType = {},
  homeAssetType,
  homeAssetTypeSymbol,
  useNotional,
}) => (
  <Table className="extra-compact" unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>{title}</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Combined</Table.HeaderCell>
        {assetTypes
          .filter((asset) => asset !== COMBINED)
          .map((assetType, i) => (
            <Table.HeaderCell key={assetType} textAlign="center">
              {assetType} Only
            </Table.HeaderCell>
          ))}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {renderRows({
        balancesByAssetType,
        homeAssetType,
        homeAssetTypeSymbol,
        useNotional,
      })}
    </Table.Body>
  </Table>
);

const Buffer = ({
  balances = [],
  homeAssetTypeSymbol,
  criticalThreshold,
  warningThreshold,
}) => (
  <Table
    celled
    className="extra-compact"
    striped
    headerRow={[{ colSpan: '2', content: 'Buffer' }, 'Buffer Used', '% Used']}
    renderBodyRow={({ amounts, bufferUsed, fd, percentBufferUsed }) => ({
      key: fd,
      warning:
        getBufferStatus({
          percentBufferUsed,
          warningThreshold,
          criticalThreshold,
        }) === status.WARNING,
      error:
        getBufferStatus({
          percentBufferUsed,
          warningThreshold,
          criticalThreshold,
        }) === status.CRITICAL,
      cells: [
        getFundsDesignationTextByValue(fd),
        {
          textAlign: 'right',
          className: 'mono',
          content: (
            <Fragment>
              <span className="pull-left">{homeAssetTypeSymbol}</span>
              {/* Calculate total buffer */}
              <span>
                {format(
                  Big(bufferUsed).plus(
                    Big(_.get(amounts, [COMBINED, 'excessDeficiency'], 0)),
                  ),
                )}
              </span>
            </Fragment>
          ),
        },
        {
          textAlign: 'right',
          className: 'mono',
          content: (
            <Fragment>
              <span className="pull-left">{homeAssetTypeSymbol}</span>
              <span>{formatNegative(format(bufferUsed))}</span>
            </Fragment>
          ),
        },
        {
          content: (
            <Progress
              className="compact"
              error={
                getBufferStatus({
                  percentBufferUsed,
                  warningThreshold,
                  criticalThreshold,
                }) === status.CRITICAL
              }
              label={`${_(percentBufferUsed).formatNegative().value()}%`}
              percent={percentBufferUsed}
              size="small"
              warning={
                getBufferStatus({
                  percentBufferUsed,
                  warningThreshold,
                  criticalThreshold,
                }) === status.WARNING
              }
            />
          ),
          textAlign: 'right',
          className: 'mono',
        },
      ],
    })}
    tableData={balances.filter(
      ({ fd }) => fd !== fundsDesignation[NON_SEG].value,
    )}
  />
);

const OTETable = ({ balances, homeAssetTypeSymbol }) => (
  <Table
    celled
    className="extra-compact"
    headerRow={[{ colSpan: 2, content: 'Reserved OTE' }]}
    striped
    tableData={balances.filter(
      ({ fd }) => fd !== fundsDesignation[NON_SEG].value,
    )}
    renderBodyRow={({ fd, amounts }) => ({
      key: fd,
      cells: [
        getFundsDesignationTextByValue(fd),
        {
          textAlign: 'right',
          className: `${balanceCellClass(_.get(amounts, [COMBINED, 'ote']))} mono`,
          content: [
            <span className="pull-left">{homeAssetTypeSymbol}</span>,
            <span>
              {_(_.get(amounts, [COMBINED, 'ote']))
                .format()
                .formatZero()
                .formatNegative()
                .value()}
            </span>,
          ],
        },
      ],
    })}
  />
);

export const OpenInterest = ({ oi }) => (
  <Segment>
    <Statistic.Group widths={1} size="small">
      <Statistic>
        <Statistic.Label>Open Interest</Statistic.Label>
        <Statistic.Value>
          {format(oi) || <span className="grey">&mdash;</span>}
        </Statistic.Value>
      </Statistic>
    </Statistic.Group>
  </Segment>
);

const ClosingPrices = ({ byAssetType = [], homeAssetTypeSymbol }) => (
  <Table
    celled
    className="extra-compact"
    headerRow={[{ colSpan: 2, content: 'Closing Prices (in USD)' }]}
    renderBodyRow={({ assetType = '', closingPx = '' }) => ({
      key: assetType,
      cells: [
        assetType,
        {
          textAlign: 'right',
          className: 'mono',
          content: (
            <Fragment>
              <span className="pull-left">{homeAssetTypeSymbol}</span>
              <span>{format(closingPx)}</span>
            </Fragment>
          ),
        },
      ],
    })}
    striped
    tableData={byAssetType}
  />
);

const BufferAlertThreshold = ({ criticalThreshold, warningThreshold }) => (
  <Table celled className="extra-compact" striped>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan={2}>Buffer Alert Threshold</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Warning</Table.Cell>
        <Table.Cell className="mono" warning>
          {warningThreshold}%
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Critical</Table.Cell>
        <Table.Cell className="mono" error>
          {criticalThreshold}%
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export const BufferAlerts = ({
  balances,
  criticalThreshold,
  warningThreshold,
}) =>
  balances.map(({ fd, percentBufferUsed }) => {
    if (
      getBufferStatus({
        percentBufferUsed,
        warningThreshold,
        criticalThreshold,
      }) === status.CRITICAL
    ) {
      return (
        <Message
          content={`Buffer usage critical for ${getFundsDesignationTextByValue(fd)},
              add funds to this account immediately!`}
          error
          header={`${getFundsDesignationTextByValue(fd)} Buffer Usage Critical`}
          icon="warning circle"
          size="large"
        />
      );
    } else if (
      getBufferStatus({
        percentBufferUsed,
        warningThreshold,
        criticalThreshold,
      }) === status.WARNING
    ) {
      return (
        <Message
          content={`Buffer usage warning for ${getFundsDesignationTextByValue(fd)}.
            Please closely monitor this account's balance and consider transferring in funds.`}
          header={`${getFundsDesignationTextByValue(fd)} Buffer Usage Warning`}
          icon="warning sign"
          size="medium"
          warning
        />
      );
    }

    return null;
  });

class FundsSegregationBalances extends PureComponent {
  state = { useNotional: true };

  render = () => {
    const {
      assetTypes,
      balances,
      balancesByAssetType,
      fetchFundsSegregationBalances,
      homeAssetType,
      loading,
      oi,
      segregatedBufferCriticalThreshold,
      segregatedBufferWarningThreshold,
    } = this.props;
    const { useNotional } = this.state;
    const homeAssetTypeSymbol = mapAssetTypeToSymbol(homeAssetType);

    return (
      <Fragment>
        <div className="flex-row space-between">
          <Header as="h1" content="Designated Funds Balances" />
          <NavLink to="/balances">
            View Balances By Account
            <Icon name="arrow right" />
          </NavLink>
        </div>
        <BufferAlerts
          balances={balances}
          criticalThreshold={segregatedBufferCriticalThreshold}
          warningThreshold={segregatedBufferWarningThreshold}
        />
        <Segment basic textAlign="right" className="extra-compact">
          <OptionsToolbar
            groupProps={{ compact: true }}
            refreshProps={{
              id: 'refresh',
              primary: true,
              refresh: fetchFundsSegregationBalances,
              size: 'small',
            }}
            loading={loading}
            toggleProps={{
              id: 'notional-toggle',
              size: 'small',
              text: `Convert to ${homeAssetType}`,
              onToggle: () => this.setState({ useNotional: !useNotional }),
              toggled: useNotional,
            }}
          />
        </Segment>
        <Segment basic compact loading={loading} className="extra-compact">
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <BalancesTable
                  title="Segregated Funds Report"
                  assetTypes={assetTypes}
                  balancesByAssetType={balancesByAssetType}
                  homeAssetType={homeAssetType}
                  homeAssetTypeSymbol={homeAssetTypeSymbol}
                  useNotional={useNotional}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Buffer
                  balances={balances}
                  homeAssetType={homeAssetType}
                  homeAssetTypeSymbol={homeAssetTypeSymbol}
                  criticalThreshold={segregatedBufferCriticalThreshold}
                  warningThreshold={segregatedBufferWarningThreshold}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <BufferAlertThreshold
                  criticalThreshold={segregatedBufferCriticalThreshold}
                  warningThreshold={segregatedBufferWarningThreshold}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <OTETable
                  balances={balances}
                  homeAssetTypeSymbol={homeAssetTypeSymbol}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <ClosingPrices
                  byAssetType={_.get(
                    balances,
                    ['0', 'amounts', 'byAssetType'],
                    [],
                  )}
                  homeAssetTypeSymbol={homeAssetTypeSymbol}
                />
              </Grid.Column>
              <Grid.Column>
                <OpenInterest oi={oi} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Fragment>
    );
  };
}

FundsSegregationBalances.propTypes = {
  assetTypes: PropTypes.arrayOf(PropTypes.string),
  balances: PropTypes.arrayOf(
    PropTypes.shape({
      fd: PropTypes.string,
      bufferUsed: PropTypes.string,
      percentBufferUsed: PropTypes.string,
      amounts: PropTypes.objectOf(PropTypes.any),
    }),
  ),
  balancesByAssetType: PropTypes.objectOf(PropTypes.any),
  fetchFundsSegregationBalances: PropTypes.func.isRequired,
  homeAssetType: PropTypes.string,
  loading: PropTypes.bool,
  oi: PropTypes.string,
  segregatedBufferCriticalThreshold: PropTypes.string,
  segregatedBufferWarningThreshold: PropTypes.string,
};

FundsSegregationBalances.defaultProps = {
  assetTypes: [],
  balances: [{}],
  balancesByAssetType: {},
  homeAssetType: '',
  loading: false,
  oi: '',
  segregatedBufferCriticalThreshold: '',
  segregatedBufferWarningThreshold: '',
};

export default FundsSegregationBalances;
