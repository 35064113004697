"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _bignumber = _interopRequireDefault(require("bignumber.js"));
// If no closing price is given or useNotional is false, return given quantity
// useNotional is useful when chaining several number formatting utils together, as
//  it can be toggled at will to bypass notional calculation.
var _default = function _default(_ref) {
  var _ref$px = _ref.px,
    px = _ref$px === void 0 ? '' : _ref$px,
    _ref$qty = _ref.qty,
    qty = _ref$qty === void 0 ? '' : _ref$qty,
    _ref$useNotional = _ref.useNotional,
    useNotional = _ref$useNotional === void 0 ? true : _ref$useNotional;
  if (!(0, _bignumber.default)(qty).isNaN()) {
    if (!(0, _bignumber.default)(px).isNaN() && useNotional) {
      return (0, _bignumber.default)(px).times(qty).toFixed();
    }
    return (0, _bignumber.default)(qty).toFixed();
  }
  return '';
};
exports.default = _default;