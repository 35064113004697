import React, { PureComponent } from 'react';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  withFilters,
  filteredArrayToAttrValue,
  getItemsCount,
  getItemsPaged,
  createLoadingSelector,
  sortedArrayToAttrValue,
} from 'erisxkit/client';
import Table from '../table/ExternalTable';
import { resetSlice } from '../../reducers/index';

const TableWithFilter = withFilters(Table);

const mapStateToProps = (state, props) => ({
  data: getItemsPaged(props.slice)(state),
  count: getItemsCount(props.slice)(state),
  loading: createLoadingSelector([props.routine._PREFIX])(state),
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      dispatchRoutine: props.routine,
      resetSlice,
    },
    dispatch,
  );

class GenericFilteredTable extends PureComponent {
  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.state = {
      expanded: [],
    };
  }
  componentWillReceiveProps = (nextProps) => {
    // necessary for fetch data to be called if the routineArgs change.
    if (!isEqual(nextProps.filterArgs, this.props.filterArgs)) {
      this.table.clearFilters();
    }
  };

  componentWillUnmount = () => {
    const { resetOnUnmount, resetSlice, slice } = this.props;
    resetOnUnmount && resetSlice(slice);
  };

  fetchData = (state = {}) => {
    const { routineArgs, filterArgs = [], sortable } = this.props;
    let filter = filterArgs;
    let sort = [];

    if (state.filtered.length !== 0) {
      filter = filter.concat(filteredArrayToAttrValue(state.filtered));
    }

    if (sortable && state.sorted.length !== 0) {
      sort = sortedArrayToAttrValue(state.sorted);
    }

    this.props.dispatchRoutine({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      page: state.page,
      filter,
      ...(sortable && { sort }),
      ...routineArgs,
    });
  };

  handleRowExpanded = ({ index }) => {
    const expanded = this.props.multipleExpandable
      ? {
          expanded: {
            ...this.state.expanded,
            [index]: !this.state.expanded[index],
          },
        }
      : { expanded: { [index]: !this.state.expanded[index] } };

    this.setState(expanded);
  };

  render = () => {
    const {
      title,
      data,
      metadata,
      count,
      loading,
      expandable,
      filters,
      SubComponent,
      hideFilters,
      ...rest
    } = this.props;
    let tableProps = {
      title,
      className: '-striped',
      data,
      onFetchData: this.fetchData,
      metadata,
      count,
      loading,
      noDataText: 'No data found.',
      minRows: 5,
      pageSize: 100,
      pages: Math.ceil(count / 100),
      filterable: false,
      filters: filters || [],
      sortable: true,
      getTrProps: (state, rowInfo) => ({
        onClick: () => {
          this.handleRowExpanded(rowInfo);
        },
        ...(expandable && { className: 'pointer' }),
      }),
    };

    const expandableTableProps = {
      expanded: this.state.expanded,
      SubComponent: (row) => SubComponent(row),
    };

    tableProps = {
      ...tableProps,
      ...(expandable && expandableTableProps),
    };

    return hideFilters ? (
      <Table {...tableProps} {...rest} />
    ) : (
      <TableWithFilter
        wrappedComponentRef={(c) => {
          this.table = c;
        }}
        {...tableProps}
        {...rest}
      />
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericFilteredTable);
