import { A_AFTER_B, A_BEFORE_B, A_EQUALS_B } from './constants';

const compareNumbers = (valueA: unknown, valueB: unknown): number => {
  // try to cast to number
  const numA =
    typeof valueA === 'string' || typeof valueA === 'number'
      ? parseFloat(valueA as string)
      : NaN;
  const numB =
    typeof valueB === 'string' || typeof valueB === 'number'
      ? parseFloat(valueB as string)
      : NaN;

  // Check for NaN and handle it accordingly
  const isANaN = isNaN(numA);
  const isBNaN = isNaN(numB);

  if (isANaN && isBNaN) {
    return A_EQUALS_B; 
  } else if (isANaN) {
    return A_BEFORE_B;
  } else if (isBNaN) {
    return A_AFTER_B;
  }

  // Normal numeric comparison
  if (!isANaN && !isBNaN) {
    return numA - numB;
  }
  // Default - keep ordering as-is
  return A_EQUALS_B;
};

export default compareNumbers;
