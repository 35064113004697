"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = errorReducer;
exports.getLatestError = exports.createErrorMessageSelector = exports.clearLatestError = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function errorReducer() {
  var _objectSpread2;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = action.payload;
  var matches = /(.*)[_/](REQUEST|FAILED|FAILURE)/.exec(type);

  // not a *_REQUEST / *_FAILED actions, so we ignore them
  if (!matches) return state;
  var _matches = (0, _slicedToArray2.default)(matches, 3),
    requestName = _matches[1],
    requestState = _matches[2];
  return _objectSpread(_objectSpread({}, state), {}, (_objectSpread2 = {}, (0, _defineProperty2.default)(_objectSpread2, requestName, requestState === 'FAILED' || requestState === 'FAILURE' ? payload : ''), (0, _defineProperty2.default)(_objectSpread2, "latestError", requestState === 'FAILED' || requestState === 'FAILURE' ? payload : ''), _objectSpread2));
}
var clearLatestError = function clearLatestError() {
  return {
    type: 'CLEAR_LATEST_ERROR_REQUEST'
  };
};
exports.clearLatestError = clearLatestError;
var createErrorMessageSelector = function createErrorMessageSelector(actions) {
  return function (state) {
    return (
      // returns the first error messages for actions
      // * We assume when any request fails on a page that
      //   requires multiple API calls, we show the first error
      (0, _lodash.default)(actions).map(function (action) {
        return _lodash.default.get(state, "api.error.".concat(action));
      }).compact().first() || ''
    );
  };
};
exports.createErrorMessageSelector = createErrorMessageSelector;
var getLatestError = function getLatestError(state) {
  return _lodash.default.get(state, 'api.error.latestError', '');
};
exports.getLatestError = getLatestError;