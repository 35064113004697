/**
 * Flattens a hierarchical data structure and adds a 'path' attribute to each item,
 * representing its hierarchical path based on a given label key.
 *
 * @template T - The type of the objects in the hierarchical data.
 * @param data - The hierarchical array of objects to flatten.
 * @param childKey - The key in each object that contains the nested child objects.
 * @param labelKey - The key in each object used to build the hierarchical path.
 * @param parentPath - The current hierarchical path (used during recursion).
 * @returns A flattened array of objects, each with an added 'path' attribute.
 */
// TODO - Type record as Record<String, unknown>
function getAsTreeData<T extends Record<string, any>, K extends keyof T>(
  data: T[],
  childKey: K,
  labelKey: keyof T,
  parentPath: string[] = [],
): (Omit<T, K> & { path: string[] })[] {
  if (!Array.isArray(data)) {
    // If input is not an array, return an empty array
    return [];
  }
  let flattened: (Omit<T, K> & { path: string[] })[] = [];
  
  data.forEach((item) => {
    // Build the current hierarchical path
    const currentPath = [...parentPath, item[labelKey]];

    // Create a copy of the current item, excluding the childKey, and add the 'path'
    const { [childKey]: _, ...rest } = item;
    const newItem = { ...rest, path: currentPath };

    // Add the item to the flattened array
    flattened.push(newItem);

    // Recursively process the children if they exist
    if (item[childKey] && Array.isArray(item[childKey])) {
      flattened = flattened.concat(
        getAsTreeData(item[childKey], childKey, labelKey, currentPath),
      );
    }
  });

  return flattened;
}

export default getAsTreeData;
