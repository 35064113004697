import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Header, Icon, Tab } from 'semantic-ui-react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import {
  createLoadingSelector,
  showModal,
  hideModal,
  XTable,
} from 'erisxkit/client';
import PropTypes from 'prop-types';
import { assetTypes } from '../../actions/utilActions';
import {
  ADD_BANK_ACCOUNT,
  ADD_CRYPTO_ADDRESS,
  ADD_COLLATERAL_ACCOUNT,
  ADMIN_KEY,
  OVERRIDE_TX_DESTINATION,
} from '../../constants/modalTypes';
import bankAccountsMetadata from '../../metadata/bankAccountsMetadata';
import cryptoAddressMetadata from '../../metadata/cryptoAddressMetadata';
import collateralAccountsMetadata from '../../metadata/collateralAccountsMetadata';
import { getCurrentAuthId, getSelectedUser } from '../../reducers/usersReducer';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import { signMsg } from '../../utils/methods';
import {
  fetchLinkedMemberAssetAccounts,
  createLinkedMemberBankAccountAdmin,
  updateLinkedMemberAssetAccountAdmin,
  createLinkedMemberCryptoAddress,
  createLinkedMemberCollateralAccount,
  getLinkedMemberBankAccounts,
  getLinkedMemberCryptoAddresses,
  deleteLinkedMemberAssetAccount,
  getLinkedMemberCollateralAccounts,
} from '../../reducers/linkedMemberAssetAccountsReducer';
import {
  generateHashIdPromiseCreator,
  generateCollateralHashIdPromiseCreator,
  removeTxAuthMemberHoldDelay,
} from '../../reducers/authReducer';
import { getCryptoAssetTypesAsOptions } from '../../selectors';
import { getEnv } from '../../reducers/envReducer';
import AgGridWrapper from '../../common/table/agGrid/AgGridWrapper';
import BankAccountGrid, {
  gridName,
} from '../../components/agGrid/MemberDetails/BankAccountGrid';
import BankAccountMetadataColDef from '../../components/agGrid/MemberDetails/BankAccountMetadata';

const mapStateToProps = (state) => ({
  selectedMemberId: getSelectedMemberId(state),
  values: _.get(state.form, ['add_linked_account', 'values'], {}),
  env: getEnv(state),
  authId: getCurrentAuthId(state),
  selectedUser: getSelectedUser(state),
  assetTypeOptions: getCryptoAssetTypesAsOptions(state),
  linkedMemberBankAccounts: getLinkedMemberBankAccounts(state),
  linkedMemberCryptoAddresses: getLinkedMemberCryptoAddresses(state),
  linkedMemberCollateralAccounts: getLinkedMemberCollateralAccounts(state),
  linkedMemberAssetAccountsLoading: createLoadingSelector([
    fetchLinkedMemberAssetAccounts._PREFIX,
  ])(state), // TODO: proper linked accounts method and loading selector
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      generateHashIdPromiseCreator,
      generateCollateralHashIdPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      showModal,
      hideModal,
      assetTypes,
      fetchLinkedMemberAssetAccounts,
      createLinkedMemberBankAccountAdmin,
      updateLinkedMemberAssetAccountAdmin,
      createLinkedMemberCryptoAddress,
      createLinkedMemberCollateralAccount,
      removeTxAuthMemberHoldDelay,
      deleteLinkedMemberAssetAccount,
    },
    dispatch,
  ),
});

const types = [
  {
    action: 'bank',
    filter: 'bank',
    modal: ADD_BANK_ACCOUNT,
  },
  {
    action: 'crypto address',
    filter: 'crypto',
    modal: ADD_CRYPTO_ADDRESS,
  },
  {
    action: 'collateral SSI',
    filter: 'collateral',
    modal: ADD_COLLATERAL_ACCOUNT,
  },
];

class LinkedMemberAssetAccountsContainer extends Component {
  state = {
    activeIndex: 0,
    selectedLinkedAccount: {},
  };

  componentDidMount = () => {
    this.props.assetTypes();
    this.fetchAssetAccounts();
  };

  handleTabChange = (e, data) =>
    this.setState(
      {
        activeIndex: data.activeIndex,
      },
      () => {
        // on tab switch, get accounts filtered by member and type
        this.fetchAssetAccounts();
      },
    );

  fetchAssetAccounts = () => {
    this.props.fetchLinkedMemberAssetAccounts({
      filter: [
        { attr: 'member_id', op: 'eq', value: this.props.selectedMemberId },
        { attr: 'type', op: 'eq', value: types[this.state.activeIndex].filter },
      ],
    });
  };

  addNew = () => {
    this.props.showModal(types[this.state.activeIndex].modal, {
      hideModal: this.props.hideModal,
      confirm: this.showFundingPassword,
      assetTypes: this.props.assetTypeOptions,
      env: this.props.env,
      isAdd: true,
    });
  };

  editSSI = (selectedLinkedAccount) => {
    this.setState({ selectedLinkedAccount });
    this.props.showModal(ADD_BANK_ACCOUNT, {
      hideModal: this.props.hideModal,
      confirm: () => this.showFundingPassword(false),
      assetTypes: this.props.assetTypeOptions,
      env: this.props.env,
      linkedAccount: selectedLinkedAccount,
      isAdd: false,
    });
  };

  override = (selectedLinkedAccount) => {
    this.setState({ selectedLinkedAccount });
    this.props.showModal(OVERRIDE_TX_DESTINATION, {
      hideModal: this.props.hideModal,
      confirm: this.showFundingPasswordOverride,
      selectedLinkedAccount,
    });
  };

  showFundingPassword = (isAdd = true) => {
    this.props.showModal(ADMIN_KEY, {
      submit: isAdd ? this.confirm : this.edit,
    });
  };

  showFundingPasswordOverride = () => {
    this.props.showModal(ADMIN_KEY, {
      submit: this.confirmRemoveTxAuthHoldDelay,
    });
  };

  confirmRemoveTxAuthHoldDelay = (password) => {
    let hashOrAddr;
    const { selectedLinkedAccount = {} } = this.state;
    // if we're overriding a bank or collateral account
    if (this.state.activeIndex === 0 || this.state.activeIndex === 2) {
      hashOrAddr = selectedLinkedAccount.hashId;
    } else if (this.state.activeIndex === 1) {
      // if we're overriding a crypto addr
      hashOrAddr = selectedLinkedAccount.address;
    }

    const { selectedMemberId, authId } = this.props;
    const linkedAccountId = _.get(selectedLinkedAccount, 'id', '');
    const msg = [
      selectedMemberId,
      hashOrAddr,
      authId,
      selectedLinkedAccount.assetType,
      Date.now().toString(),
    ];

    const sig = signMsg(this.props.authId, password, msg);
    this.props.removeTxAuthMemberHoldDelay({
      linkedAccountId,
      msg,
      sig,
    });
    this.props.hideModal();
    this.props.hideModal();
  };

  confirmBank = (password, isAdd = true) => {
    const { selectedMemberId, authId } = this.props;
    const {
      accountNumber,
      routingNumber,
      additionalSsi = '',
    } = this.props.values;
    const { selectedLinkedAccount = {} } = this.state;

    this.props
      .generateHashIdPromiseCreator({
        accountNumber,
        routingNumber,
      })
      .then((successPayload) => {
        if (isAdd) {
          const msg = [
            authId,
            selectedMemberId,
            successPayload.hashId,
            'USD',
            Date.now().toString(),
          ];
          const sig = signMsg(authId, password, msg);
          const payload = {
            ...this.props.values,
            memberId: selectedMemberId,
            sig,
            msg,
          };
          this.props.createLinkedMemberBankAccountAdmin(payload);
        } else {
          let amount = '0.0';
          const msg = [
            selectedMemberId,
            authId,
            successPayload.hashId,
            'USD',
            amount,
            Date.now().toString(),
          ];
          const sig = signMsg(authId, password, msg);
          const payload = {
            id: selectedLinkedAccount.id,
            update: {
              additionalSsi: additionalSsi,
            },
            sig,
            msg,
          };
          this.props.updateLinkedMemberAssetAccountAdmin(payload);
        }
        this.props.hideModal();
        this.props.hideModal();
      });
  };

  confirmCrypto = (password) => {
    const { selectedMemberId, authId } = this.props;
    const { assetType, address } = this.props.values;
    const msg = [
      authId,
      selectedMemberId,
      address,
      assetType,
      Date.now().toString(),
    ];
    const sig = signMsg(authId, password, msg);
    const payload = {
      ...this.props.values,
      memberId: selectedMemberId,
      sig,
      msg,
    };
    this.props.createLinkedMemberCryptoAddress(payload);
    this.props.hideModal();
    this.props.hideModal();
  };

  confirmCollateral = (password) => {
    const { counterpartyAba, counterpartyCustomer } = this.props.values;
    const { selectedMemberId, authId } = this.props;
    this.props
      .generateCollateralHashIdPromiseCreator({
        counterpartyAba,
        counterpartyCustomer,
      })
      .then((successPayload) => {
        const msg = [
          authId,
          selectedMemberId,
          successPayload.hashId,
          'collateral',
          Date.now().toString(),
        ];
        const sig = signMsg(authId, password, msg);
        const payload = {
          ...this.props.values,
          memberId: selectedMemberId,
          sig,
          msg,
        };
        this.props.createLinkedMemberCollateralAccount(payload);
        this.props.hideModal();
        this.props.hideModal();
      });
  };

  confirm = (password) => {
    if (this.state.activeIndex === 0) {
      this.confirmBank(password);
    } else if (this.state.activeIndex === 1) {
      this.confirmCrypto(password);
    } else if (this.state.activeIndex === 2) {
      this.confirmCollateral(password);
    }
  };

  edit = (password) => {
    if (this.state.activeIndex === 0) {
      this.confirmBank(password, false);
    }
  };

  remove = (linkedAccount) => {
    this.props.showModal(ADMIN_KEY, {
      submit: (password) => this.deleteSSI(linkedAccount, password),
    });
  };

  deleteSSI = ({ id, assetType, address, hashId }, password) => {
    const { selectedMemberId, authId } = this.props;
    let identifier = '';
    if (this.state.activeIndex === 0) {
      identifier = hashId;
    }
    if (this.state.activeIndex === 1) {
      identifier = address;
    }
    const msg = [
      authId,
      selectedMemberId,
      identifier,
      assetType,
      'remove',
      Date.now().toString(),
    ];
    const sig = signMsg(authId, password, msg);

    this.props.deleteLinkedMemberAssetAccount({
      linkedAccountId: id,
      msg,
      sig,
    });
    this.props.hideModal();
  };
  // CO-2325 paginate all SSI in front-end
  tableProps = {
    loading: this.props.linkedMemberAssetAccountsLoading,
    minRows: 3,
    getTdProps: (state, rowInfo, column) => ({
      className: column.id === 'more' && 'td-with-dropdown',
    }),
  };

  panes = [
    // TODO: filter each table by asset type, status, and date added.
    // Date added should be a Flatpickr date range.
    // To be completed as part of CO-2591.
    {
      url: 'bank_accounts',
      menuItem: 'Bank Accounts',
      render: () => (
        <AgGridWrapper
          gridName={gridName}
          agGridComponent={
            <BankAccountGrid
              isJest={!!this.props.isJest}
              data={this.props.linkedMemberBankAccounts}
              colDefs={BankAccountMetadataColDef(this.remove, this.editSSI)}
            />
          }
          reactTableComponent={
            <XTable
              data={this.props.linkedMemberBankAccounts}
              columns={bankAccountsMetadata(this.remove, this.editSSI)}
              title="BankAccounts"
              {...this.tableProps}
            />
          }
        />
      ),
    },
    {
      url: 'crypto_addresses',
      menuItem: 'Crypto Addresses',
      render: () => (
        <XTable
          data={this.props.linkedMemberCryptoAddresses}
          columns={cryptoAddressMetadata(this.override, this.remove)}
          title="BankAccounts"
          {...this.tableProps}
        />
      ),
    },
    {
      url: 'collateral_details',
      menuItem: 'Collateral Details',
      render: () => (
        <XTable
          data={this.props.linkedMemberCollateralAccounts}
          columns={collateralAccountsMetadata(this.override, this.remove)}
          title="CollateralAccounts"
          {...this.tableProps}
        />
      ),
    },
  ];

  render = () => (
    <Fragment>
      {this.props.memberDetailsView ? (
        <div className="flex-row space-between">
          <Header as="h1" dividing>
            Linked Asset Accounts (SSI)
          </Header>
          <Button
            className="add-button"
            floated="right"
            content="Add SSI"
            icon="plus"
            onClick={this.props.updateMember}
          />
        </div>
      ) : (
        <Fragment>
          <div className="flex-row space-between">
            <h4>Linked Asset Accounts (SSI)</h4>
            <Button secondary onClick={this.addNew} className="add-new">
              <Icon name="add circle" /> Add a new{' '}
              {types[this.state.activeIndex].action}
            </Button>
          </div>
          <Divider />
        </Fragment>
      )}
      <Tab panes={this.panes} onTabChange={this.handleTabChange} />
    </Fragment>
  );
}

LinkedMemberAssetAccountsContainer.propTypes = {
  assetTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  createLinkedMemberBankAccountAdmin: PropTypes.func.isRequired,
  updateLinkedMemberBankAccountAdmin: PropTypes.func.isRequired,
  createLinkedMemberCryptoAddress: PropTypes.func.isRequired,
  createLinkedMemberCollateralAccount: PropTypes.func.isRequired,
  fetchLinkedMemberAssetAccounts: PropTypes.func.isRequired,
  generateCollateralHashIdPromiseCreator: PropTypes.func.isRequired,
  generateHashIdPromiseCreator: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  linkedMemberAssetAccountsLoading: PropTypes.bool,
  linkedMemberBankAccounts: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string),
  ),
  linkedMemberCryptoAddresses: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string),
  ),
  linkedMemberCollateralAccounts: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string),
  ),
  memberDetailsView: PropTypes.bool,
  removeTxAuthMemberHoldDelay: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  updateMember: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.string),
};

LinkedMemberAssetAccountsContainer.defaultProps = {
  assetTypes: [],
  linkedMemberAssetAccountsLoading: false,
  linkedMemberBankAccounts: {},
  linkedMemberCryptoAddresses: {},
  linkedMemberCollateralAccounts: {},
  memberDetailsView: false,
  updateMember: false,
  values: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkedMemberAssetAccountsContainer);
