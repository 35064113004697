export default [
  {
    Header: 'Profile ID',
    id: 'profileId',
    accessor: 'profileId',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Profile Name',
    id: 'name',
    accessor: 'name',
    minWidth: 150,
    uneditable: false,
  },
  {
    Header: 'Created At',
    id: 'createdAt',
    accessor: 'createdAt',
    minWidth: 200,
    uneditable: true,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 200,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
]