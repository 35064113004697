import React, { useEffect, useRef, useState } from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from '../../../common/table/agGrid/AgGridCommon';

interface SystemApplicationsGridProps {
  data: any;
  colDefs: any;
  isJest?: boolean;
}

const defaultGridOptions: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true,
  defaultColDef: {
    resizable: true,
    sortable: true,
    filter: 'text',
    floatingFilter: true,
  },
};

export const gridName = 'system-applications';

const SystemApplicationsGrid: React.FC<SystemApplicationsGridProps> = ({
  data,
  colDefs,
  isJest,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName,
  });

  useEffect(() => {
    setRowData(data);
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [data]);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={defaultGridOptions}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
        />
      )}
    </div>
  );
};

export default SystemApplicationsGrid;
