import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { hideModal, getAllModalTypes, getAllArgs } from 'erisxkit/client';

/** Modal Components */
import ReconciliationModalContainer from './ReconciliationModalContainer';
import CreateAccount from './CreateAccountModalContainer';
import ApproveReject from './ApproveRejectModalContainer';
import AddRemoveAccountsContainer from './MemberUsers/AddRemoveAccountsContainer';
import CreateMember from './MemberUsers/CreateMember';
import ConfirmNoPostToExchange from './ManualEntries/ConfirmNoPostToExchange';
import ConfirmExternalTransferContainer from './ManualEntries/ConfirmExternalTransferContainer';
/** Modal Type Constants */
import * as modalTypes from '../constants/modalTypes';
import AddRemoveMembersContainer from './ClearingMemberAccounts/AddRemoveMembersContainer';
import AddCryptoAddress from './MemberUsers/AddCryptoAddress';
import AddBankAccount from './MemberUsers/AddBankAccount';
import AddCollateralAccount from './Members/AddCollateralAccount';
import CreateAdminKeyContainer from './CreateAdminKeyContainer';
import AdminKey from '../common/components/AdminKey';
import OverrideTxDestination from '../components/MemberUsers/OverrideTxDestination';
import ConfirmActionWithPayload from '../components/ConfirmActionWithPayload';
import TaskLog from '../components/SystemLogs/TaskLog';
import CreateManualExternalTransactionContainer from './CreateManualExternalTransactionContainer';
import CreateSpotProduct from '../components/Products/CreateSpotProduct';
import CreateCollateralContract from '../components/Products/CreateCollateralContract';
import CreateCollateralProduct from '../components/Products/CreateCollateralProduct';
import CreateFuturesContract from '../components/Products/CreateFuturesContract';
import CreateFuturesProductContainer from './Products/CreateFuturesProductContainer';
import GeneralJournalConfirmModal from './ManualEntries/GeneralJournalConfirmModal';
import CreateEmarketAccount from './ManualOnboarding/CreateEmarketAccount';
import DeliveryModalContainer from './Balances/DeliveryModalContainer';
import CreateLedger from '../components/Ledgers/CreateLedger';
import AddUBOContainer from './Members/CreateMember/AddUBOContainer';
import CreateSubExchangeModalContainer from './CreateSubExchangeModalContainer';
import ModalRiskAlertStatus from '../components/RiskAlert/ModalRiskAlertStatus';
import ViewPhoto from '../common/components/ViewPhoto';
import ModalEditOnboardingNote from '../components/ModalEditOnboardingNote';
import ModalBlockTradeRequest from '../components/ModalBlockTradeRequest';
import ConfirmTransfer from '../components/ConfirmTransfer';
import ConfirmRejectSettlement from '../components/ConfirmRejectSettlement';
import ActionStatus from '../common/components/ActionStatus';
import ModalMarginRates from '../components/MarginRates/ModalMarginRates';
import ModalAddRemoveAuthorizationIPs from '../components/ModalAddRemoveAuthorizationIPs';
import ParsedReportModal from './ParsedReportModal';
import ModalAddJurisdiction from './Configuration/ModalAddJurisdiction';
import InvalidFieldsModal from '../common/table/InvalidFieldsModal';
import ReviewEditsModal from '../common/table/ReviewEditsModal';
import ConfirmRejectPositionTransfer from '../components/ConfirmRejectPositionTransfer';
import ClearingRequestModal from '../components/ClearingRequestModal/ClearingRequestModal';
import ParticipantCGMLinkModal from './Members/ParticipantManagement/ParticipantCGMLinkModal';
import ParticipantCARLinkModal from './Members/ParticipantManagement/ParticipantCARLinkModal';
import GenericModal from '../common/GenericModal';
import AddAuthorizedTrader from './Members/BlockTradePermissions/AddAuthorizedTrader';
import BlockTradeRequestForm from './Members/BlockTradeRequests/BlockTradeRequestForm';

const MODAL_COMPONENTS = {
  [modalTypes.RECONCILIATION_MODAL]: ReconciliationModalContainer,
  [modalTypes.CREATE_ACCOUNT]: CreateAccount,
  [modalTypes.APPROVE_REJECT]: ApproveReject,
  [modalTypes.ADD_REMOVE_ACCOUNTS]: AddRemoveAccountsContainer,
  [modalTypes.CREATE_MEMBER]: CreateMember,
  [modalTypes.ADD_REMOVE_MEMBERS]: AddRemoveMembersContainer,
  [modalTypes.CREATE_ADMIN_KEY]: CreateAdminKeyContainer,
  [modalTypes.ADMIN_KEY]: AdminKey,
  [modalTypes.ADD_BANK_ACCOUNT]: AddBankAccount,
  [modalTypes.ADD_CRYPTO_ADDRESS]: AddCryptoAddress,
  [modalTypes.ADD_COLLATERAL_ACCOUNT]: AddCollateralAccount,
  [modalTypes.OVERRIDE_TX_DESTINATION]: OverrideTxDestination,
  [modalTypes.CONFIRM_ACTION_WITH_PAYLOAD]: ConfirmActionWithPayload,
  [modalTypes.CONFIRM_NO_POST_TO_EXCHANGE]: ConfirmNoPostToExchange,
  [modalTypes.WITHDRAWAL_CONFIRM]: ConfirmExternalTransferContainer,
  [modalTypes.TASK_LOG]: TaskLog,
  [modalTypes.CREATE_MANUAL_EXTERNAL_TRANSACTION]:
    CreateManualExternalTransactionContainer,
  [modalTypes.CREATE_SPOT_PRODUCT]: CreateSpotProduct,
  [modalTypes.CREATE_COLLATERAL_CONTRACT]: CreateCollateralContract,
  [modalTypes.CREATE_COLLATERAL_PRODUCT]: CreateCollateralProduct,
  [modalTypes.CREATE_FUTURES_CONTRACT]: CreateFuturesContract,
  [modalTypes.CREATE_FUTURES_PRODUCT]: CreateFuturesProductContainer,
  [modalTypes.CREATE_SUB_EXCHANGE]: CreateSubExchangeModalContainer,
  [modalTypes.GENERAL_JOURNAL_CONFIRM_MODAL]: GeneralJournalConfirmModal,
  [modalTypes.CREATE_EMARKET_ACCOUNT]: CreateEmarketAccount,
  [modalTypes.DELIVERY]: DeliveryModalContainer,
  [modalTypes.CREATE_LEDGER]: CreateLedger,
  [modalTypes.ADD_UBO]: AddUBOContainer,
  [modalTypes.UPDATE_RISK_ALERT_STATUS]: ModalRiskAlertStatus,
  [modalTypes.VIEW_PHOTO]: ViewPhoto,
  [modalTypes.EDIT_ONBOARDING_NOTE]: ModalEditOnboardingNote,
  [modalTypes.BLOCK_TRADE_REQUEST]: ModalBlockTradeRequest,
  [modalTypes.CONFIRM_TRANSFER]: ConfirmTransfer,
  [modalTypes.CONFIRM_REJECT_SETTLEMENT]: ConfirmRejectSettlement,
  [modalTypes.ACTION_STATUS]: ActionStatus,
  [modalTypes.MARGIN_RATES]: ModalMarginRates,
  [modalTypes.ADD_REMOVE_AUTHORIZATION_IPS]: ModalAddRemoveAuthorizationIPs,
  [modalTypes.PARSED_REPORT]: ParsedReportModal,
  [modalTypes.ADD_JURISDICTION]: ModalAddJurisdiction,
  [modalTypes.TABLE_INVALID_FIELDS]: InvalidFieldsModal,
  [modalTypes.TABLE_REVIEW_CHANGES]: ReviewEditsModal,
  [modalTypes.CONFIRM_POSITION_TRANSFER]: ConfirmRejectPositionTransfer,
  [modalTypes.CLEARING_REQUEST_ACTION]: ClearingRequestModal,
  [modalTypes.TRADING_PARTICIPANT_LINK_CGM]: ParticipantCGMLinkModal,
  [modalTypes.TRADING_PARTICIPANT_LINK_CAR]: ParticipantCARLinkModal,
  [modalTypes.GENERIC_MODAL]: GenericModal,
  [modalTypes.ADD_AUTHORIZED_TRADER]: AddAuthorizedTrader,
  [modalTypes.APPROVE_REJECT_BLOCK_TRADE_REQUEST]: BlockTradeRequestForm,
};

const ModalContainer = ({ hideModal, allArgs = {}, modalTypes }) => {
  if (!modalTypes.length) {
    return null;
  }
  const allModals = modalTypes.map((modalType, index) => {
    const SpecificModal = MODAL_COMPONENTS[modalType];
    const args = allArgs[modalType] || {};

    return (
      <Modal
        dimmer={!args.dimmer ? true : args.dimmer || 'blurring'}
        closeOnDimmerClick={!args.closeOnDimmerClick ? false : undefined}
        closeOnEscape={!args.closeOnEscape ? false : undefined}
        open
        onClose={hideModal}
        className={args.className}
        size={args.size}
      >
        <SpecificModal {...args} key={modalType + index} />
      </Modal>
    );
  });

  return <span>{allModals}</span>;
};

const mapStateToProps = (state) => ({
  allArgs: getAllArgs(state),
  modalTypes: getAllModalTypes(state),
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
