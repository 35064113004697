import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import marginProfileMultipliersMetadata from '../../metadata/marginProfileMultipliersMetadata';
import {
  fetchMarginRatesMultipliers,
  getMarginRatesMultipliers,
  getMarginRateInfoDate,
} from '../../reducers/marginRatesReducer';
import XTable7 from '../../common/table/XTable7';
import AgGridWrapper from '../../common/table/agGrid/AgGridWrapper';
import MarginProfileMultipliersGrid, {gridName} from '../../components/agGrid/MarginRates/MarginProfileMultipliersGrid';
import MarginProfileMultipliersMetadataColDef from '../../components/agGrid/MarginRates/MarginProfileMultipliersMetadata';

const fetchData = () => {};

const MarginProfileMultipliers = (props) => {
  const dispatch = useDispatch();
  const marginRatesMultipliersLoading = useSelector(
    createLoadingSelector([fetchMarginRatesMultipliers._PREFIX]),
  );
  const marginRatesMultipliers = useSelector(getMarginRatesMultipliers);
  const marginRateInfoDate = useSelector(getMarginRateInfoDate);

  React.useEffect(() => {
    if (marginRateInfoDate) {
      dispatch(fetchMarginRatesMultipliers({ date: marginRateInfoDate }));
    } else {
      dispatch(fetchMarginRatesMultipliers());
    }
  }, [marginRateInfoDate]);

  return (
    <Fragment>
      <AgGridWrapper
            gridName={gridName}
            agGridComponent={<MarginProfileMultipliersGrid data={marginRatesMultipliers?.multipliers || []} loading={marginRatesMultipliersLoading} isJest={!!props?.isJest}
            colDefs={MarginProfileMultipliersMetadataColDef} />}
            reactTableComponent={
              <XTable7
                metadata={marginProfileMultipliersMetadata}
                data={marginRatesMultipliers?.multipliers || []}
                fetchData={fetchData}
                loading={marginRatesMultipliersLoading}
                count={marginRatesMultipliers?.multipliers?.length}
                showPagination={false}
                localSortingAndFiltering={true}
              />
            } 
        />
    </Fragment>
  );
};

MarginProfileMultipliers.propTypes = {
  fetchMarginRatesMultipliers: PropTypes.func.isRequired,
  marginRatesMultipliersLoading: PropTypes.bool,
  marginRatesMultipliers: PropTypes.func.isRequired,
};

MarginProfileMultipliers.defaultProps = {
  count: 0,
};

export default MarginProfileMultipliers;
