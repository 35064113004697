import React, { Component, Fragment } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import {
  showModal,
  hideModal,
  createLoadingSelector,
  inputOnChange,
  filteredArrayToObject,
  withFilters,
  XTable,
} from 'erisxkit/client';
import { Header, Dropdown } from 'semantic-ui-react';
import PropTypes, { object } from 'prop-types';
import Datetime from 'react-datetime';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { futuresDcoAccountPromiseCreator } from '../../reducers/accountsReducer';
import {
  positions,
  createCloseoutInstructionPromiseCreator,
  selectAccountHistory,
  clearPositions,
} from '../../actions/accountsActions';
import { getPositionsByContraAccountId } from '../../reducers/balancesReducer';
import {
  futuresContractsPromiseCreator,
  getFuturesContracts,
} from '../../reducers/contractsReducer';
import { FUTURES_CONTRACTS, POSITIONS } from '../../constants/actionTypes';
import {
  getSelectedAccountIdBySlice,
  getSubExchangesAsOptions,
} from '../../selectors';
import {
  subComponentMetadata,
  futuresPositionsMetadata,
} from '../../metadata/futuresPositionsMetadata';
import ExternalTable from '../../common/table/ExternalTable';
import ProductContractSelection from '../../common/containers/ProductContractSelection';
import { getFuturesProducts } from '../../reducers/futuresProductReducer';
import { fetchSubExchanges } from '../../reducers/subExchangesReducer';
import XTable7 from '../../common/table/XTable7';
import deliveryTypes from '../../constants/deliveryTypes';

const FilteredTable = withFilters(ExternalTable);

const mapStateToProps = (state) => ({
  futuresContracts: getFuturesContracts(state),
  futuresProducts: getFuturesProducts(state),
  subExchangesOptions: getSubExchangesAsOptions(state),
  futuresContractsLoading: createLoadingSelector([FUTURES_CONTRACTS])(state),
  positionsList: getPositionsByContraAccountId(state),
  positionsLoading: createLoadingSelector([POSITIONS])(state),
  selectedAccountId: getSelectedAccountIdBySlice('accounts')(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      futuresContractsPromiseCreator,
      futuresDcoAccountPromiseCreator,
      createCloseoutInstructionPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      clearPositions,
      fetchSubExchanges,
      selectAccountHistory,
      positions,
      showModal,
      hideModal,
    },
    dispatch,
  ),
});

class FuturesPositionsContainer extends Component {
  state = {
    fetchDataLoading: false,
  };

  componentDidMount() {
    this.props.fetchSubExchanges();
  }

  fetchInternalTableData = () => {};

  getFilters = () => {
    const { isDelivery, subExchangesOptions } = this.props;
    return [
      !isDelivery && {
        placeholder: 'Select SubExchange',
        component: Dropdown,
        name: 'sub_exchange_id',
        id: 'sub_exchange_id',
        label: 'Exchange Symbol',
        onChange: inputOnChange,
        options: subExchangesOptions,
        selection: true,
        clearable: true,
      },
      {
        component: ProductContractSelection,
        contractName: 'contractSymbols',
        hideSpot: true,
        name: 'contract_symbols',
        id: 'contract_symbols',
        onChange:
          (onChange) =>
          (e, { name, value }) =>
            onChange(name, value, 'eq'),
        showDetails: true,
      },
      {
        component: Datetime,
        className: 'ui input datetime',
        label: 'Maturity Date',
        name: 'expiry_date',
        eqid: 'expiry_date',
        eqdefaultValue: '',
        dateFormat: 'YYYY-MM-DD',
        timeFormat: false,
        inputProps: { placeholder: 'Select Date' },
        closeOnSelect: true,
        onChange: (onChange) => (date) => {
          onChange('expiry_date', date.format('YYYY-MM-DD'), 'eq');
        },
      },
      isDelivery && {
        placeholder: 'Delivery Type',
        component: Dropdown,
        name: 'delivery',
        id: 'delivery',
        label: 'Delivery Type',
        onChange: inputOnChange,
        options: deliveryTypes,
        selection: true,
        search: true,
      },
    ];
  };

  fetchPositions = (state) => {
    if (!this.state.fetchDataLoading) {
      return;
    }
    this.props.clearPositions();
    this.props.futuresContractsPromiseCreator().then(
      () => {
        this.props
          .futuresDcoAccountPromiseCreator()
          .then((account) => {
            this.props.selectAccountHistory(get(account, 'accountId'));
            this.props.positions({
              accountId: get(account, 'accountId'),
              excludeMargin: this.props.excludeMargin,
              ...(get(state, ['filtered', 'length'])
                ? filteredArrayToObject(state.filtered)
                : undefined),
            });
          })
          .then(() => this.setState({ fetchDataLoading: false }));
      },
      () => console.error('Failed to fetch dco account'),
    );
  };

  render = () => {
    const isDcoAccount = true;
    return (
      <Fragment>
        <div className="flex-row space-between">
          <Header as="h1">Futures Positions</Header>
        </div>
        <FilteredTable
          title="futures-positions"
          id="futures-positions-maincomponent"
          loading={
            this.props.positionsLoading || this.props.futuresContractsLoading
          }
          data={this.props.positionsList}
          filters={this.getFilters()}
          pageSize={this.props.positionsList.length}
          manual
          metadata={futuresPositionsMetadata(
            this.props.futuresProducts,
            this.props.futuresContracts,
            '',
            '',
            isDcoAccount,
            this.props.showModal,
            this.props.hideModal,
            this.props.isDelivery,
          )}
          pivotBy={['productCode', 'contractCode']}
          expanded={this.state.expanded}
          onExpandedChange={(newExpanded) => {
            this.setState({ expanded: newExpanded });
          }}
          onFetchData={(state) => {
            this.setState(
              { fetchDataLoading: true },
              this.fetchPositions(state),
            );
          }}
          SubComponent={({ row }) => (
            /*
            <XTable
              className="subtable no-stripe"
              id="futures-positions-subcomponent"
              title="futures-positions-subcomponent"
              ref={(r) => { this.checkboxTbale = r; }}
              data={get(row, ['_original', 'positions'], [])}
              showPagination={false}
              pageSize={get(row, ['_original', 'positions'], []).length}
              columns={subComponentMetadata('', '', '', row.symbol, isDcoAccount)}
            />
            */
            <div style={{ margin: '15px' }}>
              <XTable7
                title="futures-positions-subcomponent"
                metadata={subComponentMetadata(
                  '',
                  '',
                  '',
                  row.symbol,
                  isDcoAccount,
                )}
                data={get(row, ['_original', 'positions'], [])}
                fetchData={this.fetchInternalTableData}
                showHeader
                showFilter={false}
                showPagination={false}
                height="0vh"
              />
            </div>
          )}
        />
      </Fragment>
    );
  };
}

FuturesPositionsContainer.propTypes = {
  clearPositions: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isDelivery: PropTypes.bool,
  fetchSubExchanges: PropTypes.func.isRequired,
  futuresDcoAccountPromiseCreator: PropTypes.func.isRequired,
  futuresContractsPromiseCreator: PropTypes.func.isRequired,
  futuresContracts: PropTypes.arrayOf(PropTypes.any),
  futuresContractsLoading: PropTypes.bool,
  futuresProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  positions: PropTypes.func.isRequired,
  positionsList: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      positions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          qty: PropTypes.string,
          px: PropTypes.string,
        }),
      ),
    }),
  ),
  positionsLoading: PropTypes.bool,
  selectAccountHistory: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  subExchangesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  excludeMargin: PropTypes.bool,
};

FuturesPositionsContainer.defaultProps = {
  isDelivery: false,
  futuresContractsLoading: false,
  positionsList: [],
  positionsLoading: false,
  subExchangesOptions: [],
  excludeMargin: false,
  futuresProducts: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FuturesPositionsContainer);
