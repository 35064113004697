import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridCommon } from '../../../common/table/agGrid/AgGridCommon';

interface ReconciliationHistoryGridProps {
  data: ReconciliationHistoryDataType[];
  colDefs: ColDef[];
  isJest?: boolean;
  viewReconciliationHistory: (id: string) => void;
}

const defaultGridOptions: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true,
  defaultColDef: {
    resizable: true,
    sortable: true,
  },
};

export interface ReconciliationHistoryDataType {
  endTime: string;
  state: string;
  startTime: string;
  endExternalBalance: string;
  endInternalBalance: string;
}

export const gridName = 'reconciliation-history';

const ReconciliationHistoryGrid: React.FC<ReconciliationHistoryGridProps> = ({
  data,
  colDefs,
  isJest,
  viewReconciliationHistory,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<ReconciliationHistoryDataType[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(colDefs);
  }, [colDefs]);

  const { gridVisible, initialGridSettings, onStateUpdated } = useAgGridCommon({
    isJest,
    gridName,
  });

  useEffect(() => {
    setRowData(data);
    if (!initialGridSettings?.columnSizing) {
      gridRef.current?.api?.sizeColumnsToFit();
    }
  }, [data]);

  const onRowClicked = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    //We can select just 1 row at time in this table.
    viewReconciliationHistory(selectedRows[0]?.uuid || '');
  }, []);

  return (
    <div className="ag-theme-quartz">
      {gridVisible && (
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={defaultGridOptions}
          onStateUpdated={onStateUpdated}
          initialState={initialGridSettings}
          rowSelection={'single'}
          onRowClicked={onRowClicked}
        />
      )}
    </div>
  );
};

export default ReconciliationHistoryGrid;
