"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bigSumFixed = exports.bigSum = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var bigSum = function bigSum(values) {
  var accessor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (value) {
    return value;
  };
  return _lodash.default.reduce(values, function (total, value) {
    return (0, _bignumber.default)(accessor(value) || 0).plus(total);
  }, (0, _bignumber.default)(0));
};
exports.bigSum = bigSum;
var bigSumFixed = function bigSumFixed(values) {
  var accessor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (value) {
    return value;
  };
  return bigSum(values, accessor).toFixed();
};
exports.bigSumFixed = bigSumFixed;