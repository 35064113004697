import React, { Component } from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import version from '../../config/version';
import {
  isAuthorized,
  isLedgerSensitive,
  isUserRoleRestricted,
} from '../utils/methods';
import { getCurrentUser } from '../reducers/usersReducer';
import { getActiveLedgerId } from '../reducers/ledgersReducer';
import { roles } from '../constants/userRoles';

const groups = [
  {
    id: 'memberManagement',
    name: 'MEMBER MANAGEMENT',
  },
  {
    id: 'accountManagement',
    name: 'ACCOUNT MANAGEMENT',
  },
  {
    id: 'system',
    name: 'SYSTEM',
  },
  {
    id: 'transactions',
    name: 'TRANSACTIONS',
  },
  {
    id: 'manualActions',
    name: 'MANUAL ACTIONS',
  },
  {
    id: 'riskManagement',
    name: 'RISK MANAGMENT',
  },
];

const NavigationItems = [
  {
    key: 'home',
    title: 'Home',
    icon: 'home',
  },
  {
    key: 'approvals',
    title: 'Approvals',
    icon: 'check',
  },
  {
    key: 'client_transfers',
    title: 'Client Transfers',
    icon: 'check',
  },
  {
    key: 'onboarding',
    title: 'Onboarding',
  },
  {
    key: 'reconciliation',
    title: 'Reconciliation',
    icon: 'history',
  },
  {
    key: 'promotions',
    title: 'Promotions',
    url: 'promotions',
  },
  {
    key: 'balances',
    title: 'Balances',
    icon: 'money bill alternate outline',
    group: 'accountManagement',
    url: 'balances',
  },
  {
    key: 'designated_funds',
    title: 'Seg/Non-Seg',
    icon: 'money bill alternate outline',
    url: 'designated_funds',
  },
  {
    key: 'finance_accounts',
    icon: 'dollar sign',
    title: 'Finance Accounts',
  },
  {
    key: 'clearing_accounts',
    icon: 'address card outline',
    title: 'Clearing Accounts',
  },
  {
    key: 'members',
    icon: 'members',
    title: 'Members',
    group: 'memberManagement',
    restrictedRoles: [roles.RISK],
  },
  {
    key: 'member_users',
    icon: 'member_users',
    title: 'Member Users',
  },
  {
    key: 'employees',
    icon: 'id badge',
    title: 'Employees',
  },
  {
    key: 'trades',
    icon: 'exchange',
    title: 'Trades',
    group: 'transactions',
  },
  {
    key: 'requests',
    icon: 'arrow down',
    title: 'Movements',
  },
  {
    key: 'settlement_service',
    icon: 'handshake outline',
    title: 'Settlement Service',
  },
  {
    key: 'positions',
    icon: 'arrow down',
    title: 'Positions',
  },
  {
    key: 'position_transfer',
    icon: 'arrow down',
    title: 'Position Transfer',
  },
  {
    key: 'journals',
    icon: 'file',
    title: 'Journals',
  },
  {
    key: 'manual_entries',
    icon: 'money',
    title: 'Manual Entries',
    group: 'manualActions',
  },
  {
    key: 'products',
    icon: 'chart line',
    title: 'Products',
  },
  {
    key: 'core_files',
    icon: 'paper plane outline',
    title: 'Export Core Files',
  },
  {
    key: 'reports',
    icon: 'file alternate outline',
    title: 'Inbound Files',
    altRoute: 'positions',
  },
  {
    key: 'risk',
    group: 'riskManagement',
    title: 'Risk Engine',
  },
  {
    key: 'risk_alert',
    title: 'Risk Alerts',
  },
  {
    key: 'margin_rates',
    title: 'Margin Rates',
  },
  {
    key: 'margin_requirements',
    title: 'Initial Margin',
    altRoute: 'positions',
  },
  {
    key: 'risk_configurations',
    title: 'Risk Configurations',
  },
  {
    group: 'system',
    key: 'about',
    icon: 'info',
    title: 'About',
  },
  {
    key: 'alerts',
    icon: 'exclamation triangle',
    title: 'System Information',
  },
  {
    key: 'configuration',
    icon: 'cogs',
    title: 'System Configuration',
  },
  {
    key: 'surveillance',
    icon: 'eye',
    title: 'Surveillance',
  },
  {
    key: 'run_tasks',
    title: 'Run Tasks',
  },
];

class Navigation extends Component {
  state = {
    open: localStorage.getItem('nav-open') === 'true',
  };

  handleItemClick = (e, { name }) => {
    this.props.history.push(`/${name}`);
  };

  activeRoute = (route) => {
    const { path } = this.props.match;
    if (route && path) {
      // remove the '/' from path to make a strict equality check against route
      return path.substring(1) === route;
    }
    return path === '/';
  };

  getNavItems = () =>
    NavigationItems.reduce((arr, item) => {
      if (
        isUserRoleRestricted(
          this.props.currentUser?.roles,
          item?.restrictedRoles,
        )
      )
        return arr;

      if (
        isAuthorized(
          this.props.currentUser.uiViews,
          item.key,
          item?.altRoute || '',
        ) &&
        isLedgerSensitive(this.props.ledgerId, item.key)
      ) {
        item.group &&
          arr.push(
            <Menu.Item header key={item.group}>
              {groups.find((group) => group.id === item.group).name}
            </Menu.Item>,
          );
        arr.push(
          <Menu.Item
            as={NavLink}
            name={item.key}
            key={item.key}
            active={this.activeRoute(item.key)}
            to={`/${item.url || item.key}`}
            data-cy={`${item.url || item.key}-sidenav-menu-item`}
          >
            <span
              className={classnames({
                'nav-item': true,
                active: this.activeRoute(item.url || item.key),
              })}
            >
              {item.title}
            </span>
          </Menu.Item>,
        );
      }
      return arr;
    }, []);

  toggleMenu = () => {
    this.setState(
      {
        open: !this.state.open,
      },
      () => localStorage.setItem('nav-open', this.state.open),
    );
  };

  render = () => (
    <aside className={`nav-container ${this.state.open ? 'open' : 'closed'}`}>
      <Menu vertical className="nav">
        <Icon
          id="toggleIcon"
          color="grey"
          circular
          className="pull-right"
          link
          onClick={this.toggleMenu}
          name="pin"
          rotated={`${this.state.open ? 'counterclockwise' : 'clockwise'}`}
        />
        <div className="flex-column flex-column-logo">
          <div
            className="pointer logo"
            title="Cboe Clear US"
            onClick={() => this.handleItemClick(null, { name: '' })}
          />
          <span className="nav-version">{version.VERSION}</span>
        </div>
        <div className="nav-items">{this.getNavItems()}</div>
      </Menu>
      {!this.state.open && (
        <span
          className="smaller-logo closed-nav"
          title="Cboe Clear US"
          onClick={this.toggleMenu}
          role="button"
          data-cy="sidenav-closed-logo-button"
        />
      )}
    </aside>
  );
}

export default withRouter(
  connect((state) => ({
    currentUser: getCurrentUser(state),
    ledgerId: getActiveLedgerId(state),
  }))(Navigation),
);
