import _ from 'lodash';
import React, { Fragment } from 'react';
import { List, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { formatLabel } from '../../utils/methods';

const WarningMessage = ({ header, labels, message, warnings }) => (
  <Message
    warning
    icon="warning sign"
    compact
    className={`${_.isEmpty(warnings) ? 'hide' : 'show'}`}
    header={header}
    content={
      <Fragment>
        <p>{message}</p>
        <List bulleted>
          {_.chain(warnings)
            .keys()
            .map((attr) => (
              <List.Item key={attr}>{formatLabel(attr, labels)}</List.Item>
            ))
            .value()}
        </List>
      </Fragment>
    }
  />
);

WarningMessage.propTypes = {
  header: PropTypes.string,
  labels: PropTypes.objectOf(PropTypes.string),
  message: PropTypes.string,
  warnings: PropTypes.objectOf(PropTypes.string),
};

WarningMessage.defaultProps = {
  header: 'Warning: Missing Fields',
  message:
    'The following fields are empty. They are not required but it is recommended that you fill them in:',
  labels: {},
  warnings: {},
};

export default WarningMessage;
