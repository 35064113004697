import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { Form, Select } from 'semantic-ui-react';
import { fetchUsers, getUsersList } from '../../reducers/usersReducer';
import { getSelectorAsOptions } from '../../selectors';

const firstOption = [
  {
    key: null,
    text: 'Show All',
    value: null,
    description: null,
  },
];

// TODO: generalize this further into a generic search component
const UserSelectionContainer = (props) => {
  const dispatch = useDispatch();
  const options = useSelector(
    getSelectorAsOptions(getUsersList(), {
      key: 'userId',
      text: 'email',
      value: 'userId',
      description: 'userId',
    }),
  );
  const loading = useSelector(createLoadingSelector(['USERS']));
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChangeDebounceFn = (searchQuery) => {
    dispatch(
      fetchUsers({
        filter: [
          { attr: 'email', op: 'contain', value: searchQuery },
          ...props.additionalFilters,
        ],
        limit: 10,
      }),
    );
  };

  const searchChangeDebounceFn = useCallback(
    _.debounce(handleSearchChangeDebounceFn, 500),
    [],
  );

  const handleSearchChange = (e, { searchQuery }) => {
    searchChangeDebounceFn(searchQuery);
    setSearchQuery({ searchQuery });
  };

  return (
    <Form.Field
      control={Select}
      search
      selection
      options={props.showAllOption ? [...firstOption, ...options] : options}
      onChange={(e, { value }) =>
        _.get(props, ['input', 'onChange'], () => {})(value)
      }
      defaultValue={_.get(props, ['input', 'value'])}
      value={_.get(props, ['input', 'value'])}
      onSearchChange={handleSearchChange}
      loading={loading}
      noResultsMessage={searchQuery ? '' : 'Search for a user.'}
      additionalFilters={props.additionalFilters}
      className={props.className}
      input={props.input}
      multiple={props.multiple}
      name={props.name}
      placeholder={props.placeholder}
      showAllOption={props.showAllOption}
    />
  );
};

UserSelectionContainer.propTypes = {
  additionalFilters: PropTypes.shape({
    attr: PropTypes.string,
    op: PropTypes.string,
    value: PropTypes.string,
  }),
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

// Specifies the default values for props:
UserSelectionContainer.defaultProps = {
  additionalFilters: {},
  name: 'userId',
  placeholder: 'Email of user',
  multiple: false,
  showAllOption: false,
  loading: false,
  options: [],
};

export default UserSelectionContainer;
