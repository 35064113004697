import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { bindPromiseCreators } from 'redux-saga-routines';
import { getFormValues, destroy } from 'redux-form';
import { bindActionCreators } from 'redux';
import { showModal, hideModal } from 'erisxkit/client';
import {
  createGeneralJournalPromiseCreator,
  generalJournalCategories,
} from '../../actions/manualEntryActions';
import { assetTypes } from '../../actions/utilActions';
import GeneralJournalAdvanced from '../../components/ManualEntries/GeneralJournalAdvanced';
import { getGeneralJournalCategoriesAsOptions } from '../../reducers/manualEntriesReducer';
import { getActiveLedgerId } from '../../reducers/ledgersReducer';
import { getAssetTypes } from '../../selectors';
import { GENERAL_JOURNAL_CONFIRM_MODAL } from '../../constants/modalTypes';

const mapStateToProps = (state) => ({
  activeLedgerId: getActiveLedgerId(state),
  assetTypesList: getAssetTypes(state),
  assetTypesLoading: createLoadingSelector(['ASSET_TYPES'])(state),
  categories: getGeneralJournalCategoriesAsOptions(state),
  categoriesLoading: createLoadingSelector(['GENERAL_JOURNAL_CATEGORIES'])(
    state,
  ),
  values: getFormValues('general-journal')(state) || {},
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      createGeneralJournalPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      assetTypes,
      generalJournalCategories,
      showModal,
      hideModal,
      destroy,
    },
    dispatch,
  ),
});

class GeneralJournalContainer extends Component {
  componentDidMount = () => {
    const { assetTypes, generalJournalCategories } = this.props;
    assetTypes();
    generalJournalCategories();
  };

  handleSubmit = () => {
    const {
      showModal,
      hideModal,
      createGeneralJournalPromiseCreator,
      values,
      destroy,
    } = this.props;
    showModal(GENERAL_JOURNAL_CONFIRM_MODAL, {
      header: 'Create General Journal',
      hideModal,
      closeOnDimmerClick: false,
      closeOnEscape: true,
      values,
      onConfirm: (values) => {
        createGeneralJournalPromiseCreator(values).then(
          () => {
            hideModal();
            destroy('general-journal');
          },
          () => console.log('failed'),
        );
      },
    });
  };

  render = () => (
    <GeneralJournalAdvanced
      activeLedgerId={this.props.activeLedgerId}
      assetTypes={this.props.assetTypesList}
      handleSubmit={this.handleSubmit}
      assetTypesLoading={this.props.assetTypesLoading}
      categoriesLoading={this.props.categoriesLoading}
      categories={this.props.categories}
      formValues={this.props.values}
    />
  );
}

GeneralJournalContainer.propTypes = {
  assetTypes: PropTypes.func.isRequired,
  createGeneralJournal: PropTypes.func.isRequired,
  assetTypesList: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      description: PropTypes.string,
      isFiat: PropTypes.bool,
    }),
  ).isRequired,
  assetTypesLoading: PropTypes.bool,
  categoriesLoading: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.string),
  generalJournalCategories: PropTypes.func.isRequired,
};

GeneralJournalContainer.defaultProps = {
  assetTypesLoading: false,
  categoriesLoading: false,
  categories: [],
  createGeneralJournal: ()=>{},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralJournalContainer);
