import React, { Component, Fragment } from 'react';
import { get } from 'lodash';
import {
  Modal,
  Button,
  Icon,
  Header,
  List,
  Checkbox,
  Segment,
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import JSONTree from 'react-json-tree';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal, showModal } from 'erisxkit/client';
import {
  approve,
  fetchApprovalReqPayload,
} from '../actions/approvalReqsActions';
import {
  getSelectedApprovalReqId,
  getApprovalReqPayload,
  rejectPromiseCreator,
} from '../reducers/approvalReqsReducer';
import { updateUser, fetchUsersPromiseCreator } from '../reducers/usersReducer';
import { RESUBMIT_KYC } from '../constants/userStates';
import { FACE_ID_IMAGES, ID_IMAGES } from '../constants/appStates';
import { UNASSIGNED_EMPLOYEE_USER_ID } from '../common/containers/EmployeeSelectionContainer';
import { sendEmail } from '../reducers/manualOnboardingReducer';
import { ACTION_STATUS } from '../constants/modalTypes';
import {
  formatMoneyDescriptionFiatTransaction,
  formatMoneyDescriptionGeneralJournal,
  formatMoneyPayloadGeneralJournal,
} from '../utils/methods';

const mapStateToProps = (state = {}) => ({
  selectedApprovalReqId: getSelectedApprovalReqId(state),
  approvalReqPayload: getApprovalReqPayload(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    { rejectPromiseCreator, fetchUsersPromiseCreator },
    dispatch,
  ),
  ...bindActionCreators(
    {
      hideModal,
      showModal,
      approve,
      fetchApprovalReqPayload,
      updateUser,
      sendEmail,
    },
    dispatch,
  ),
});

const RESUBMIT_FACE_ID = 'resubmit_face_id';
const RESUBMIT_ID_DOC = 'resubmit_id_doc';
export const isPendingPiiApprovalReq = (appReq) =>
  !!get(appReq, 'description', '').includes('Onboarding - 16');

class ApproveModalContainer extends Component {
  state = {};

  componentDidMount = () => {
    this.props.fetchApprovalReqPayload(this.props.selectedApprovalReqId);
  };

  onClose = () => {
    this.props.hideModal();
  };

  onReject = () => {
    this.props
      .rejectPromiseCreator({ uuid: this.props.selectedApprovalReqId })
      .then(
        (success) => {
          this.props.hideModal();

          this.rejectionActions()();
        },
        (e) => {
          console.error('Rejection failed', e);
        },
      );
  };

  onApprove = () => {
    this.props.approve(this.props.selectedApprovalReqId);
    this.props.hideModal();
  };

  rejectionOptionsChecked = () =>
    Object.keys(this.state).some((key) => !!this.state[key]);

  rejectionActions = () => {
    if (isPendingPiiApprovalReq(this.props.selectedApprovalReq)) {
      return () => {
        const { approvalReqPayload = {} } = this.props;
        // get user_id from the payload doc_id
        const userId = approvalReqPayload.docId;

        this.props
          .fetchUsersPromiseCreator({
            includeName: true,
            filter: [
              {
                attr: 'user_id',
                op: 'eq',
                value: [userId],
              },
            ],
          })
          .then((result) => {
            const user = result.users[0];
            const { email } = user;
            const { firstName } = user;

            if (this.rejectionOptionsChecked()) {
              this.props.showModal(ACTION_STATUS, {
                actions: {
                  UPDATE_USER:
                    'Updates user state, app_state, and assigned_to fields.',
                  [sendEmail._PREFIX]: 'Sends email to user.',
                },
                size: 'mini',
              });

              // set user state to 'resubmit_kyc'
              // set user app_state to the appropriate one
              // change assigned to to 'unassigned'

              let appState;
              if (this.state[RESUBMIT_FACE_ID]) {
                appState = FACE_ID_IMAGES;
              } else if (this.state[RESUBMIT_ID_DOC]) {
                appState = ID_IMAGES;
              }

              const update = {
                state: RESUBMIT_KYC,
                appState,
                assigned_employee_user_id: UNASSIGNED_EMPLOYEE_USER_ID,
              };
              this.props.updateUser({ userId, update });

              // send email
              this.props.sendEmail({
                emailId: 'resubmit_documents',
                recipient: email,
                contactProperties: {
                  firstname: firstName,
                  resubmitLiveness: this.state[RESUBMIT_FACE_ID]
                    ? 'Required'
                    : false,
                  resubmitId: this.state[RESUBMIT_ID_DOC] ? 'Required' : false,
                },
              });
            } else {
              // send rejection email
              this.props.sendEmail({
                emailId: 'rejected',
                recipient: email,
                contactProperties: {
                  firstname: firstName,
                },
              });
            }
          });
      };
    }
  };

  toggle = (id) => this.setState((prevState) => ({ [id]: !prevState[id] }));

  render() {
    const { selectedApprovalReq = {}, approvalReqPayload } = this.props;
    let rejectionOptions = null;

    // quick fix for CO-3081
    if (get(approvalReqPayload, 'reconciledBalance')) {
      delete approvalReqPayload.reconciledBalance;
    }

    // if it's a review pii approval req, then we need to show rejection options
    if (isPendingPiiApprovalReq(selectedApprovalReq)) {
      rejectionOptions = (
        <Segment className="flex-column">
          <Header> Please choose the required actions for the user:</Header>
          <Checkbox
            style={{ marginBottom: '5px' }}
            toggle
            label="Resubmit Face ID"
            onChange={() => this.toggle(RESUBMIT_FACE_ID)}
          />
          <Checkbox
            toggle
            label="Resubmit ID Document"
            onChange={() => this.toggle(RESUBMIT_ID_DOC)}
          />
        </Segment>
      );
    }

    let descriptionToShow = selectedApprovalReq?.description;

    const approvalReqPayloadToShow = {
      ...approvalReqPayload,
    };

    const generalJournalItems = approvalReqPayload?.generalJournalItems;

    if (approvalReqPayload?.docType === 'FiatTransactionRequest') {
      descriptionToShow =
        formatMoneyDescriptionFiatTransaction(descriptionToShow);
    }

    if (
      generalJournalItems &&
      generalJournalItems?.find((x) => x.assetType === 'USD') !== undefined
    ) {
      if (selectedApprovalReq?.description) {
        descriptionToShow = formatMoneyDescriptionGeneralJournal(
          selectedApprovalReq.description,
        );
      }
      approvalReqPayloadToShow.generalJournalItems =
        formatMoneyPayloadGeneralJournal(generalJournalItems);
    }

    return (
      <Fragment>
        <Header icon="check circle" content="Approve or reject this action?" />
        <Modal.Content>
          <Header content="Request:" />
          <List
            className="pre"
            divided
            relaxed
            items={[selectedApprovalReq.initiator, descriptionToShow]}
          />

          {rejectionOptions ? (
            <Header>
              <Header.Content>Rejection Options:</Header.Content>
              <Header.Subheader>
                Options that are applied only when the approval request is{' '}
                <b style={{ color: 'red' }}>rejected.</b>
              </Header.Subheader>
            </Header>
          ) : null}
          {rejectionOptions}

          <Header content="Payload:" />
          <JSONTree data={approvalReqPayloadToShow} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="cancel"
            className={
              selectedApprovalReq.state === 'pending' ? 'pull-left' : ''
            }
            id="cancel"
            onClick={this.onClose}
          >
            <Icon name="close" /> Cancel
          </Button>
          {selectedApprovalReq.state === 'pending' && (
            <Fragment>
              <Button
                color="red"
                id="reject"
                onClick={this.onReject}
                data-cy="reject-approval-req"
              >
                <Icon name="thumbs down" /> Reject
              </Button>
              <Button
                color="green"
                id="approve"
                onClick={this.onApprove}
                disabled={this.rejectionOptionsChecked()}
                data-cy="approve-approval-req"
              >
                <Icon name="thumbs up" /> Approve
              </Button>
            </Fragment>
          )}
        </Modal.Actions>
      </Fragment>
    );
  }
}

ApproveModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  selectedApprovalReqId: PropTypes.string.isRequired,
};

ApproveModalContainer.defaultProps = {
  hideModal: () => {},
  approve: () => {},
  reject: () => {},
  selectedApprovalReqId: '',
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApproveModalContainer);
