"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datetimeOnchange = exports.checkboxOnChange = exports.inputOnChange = exports.sortedArrayToAttrValue = exports.filteredArrayToAttrFalsyValue = exports.filteredArrayToAttrValue = exports.filteredArrayToObject = exports.paramsToObject = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _lodash = require("lodash");
var _changeCaseObject = require("change-case-object");
var _moment = _interopRequireDefault(require("moment"));
var _filterOps = _interopRequireDefault(require("../../constants/filterOps"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) { n[e] = r[e]; } return n; }
// takes an iterable 'entries' and converts it to an array of 'id, value'
// objects that react-table uses for it's 'filtered' prop
var paramsToObject = function paramsToObject(entries) {
  if (entries == null || typeof entries[Symbol.iterator] !== 'function') {
    console.warn('Bad argument passed to paramsToObject');
    return [];
  }
  var result = [];
  var _iterator = _createForOfIteratorHelper(entries),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var entry = _step.value;
      // each 'entry' is a [key, value] tupple
      var _entry = (0, _slicedToArray2.default)(entry, 2),
        id = _entry[0],
        value = _entry[1]; // check if it's a min- max- query and set the operator variable accordingly
      var op = _filterOps.default[id] || 'match';
      if (id.search('min-') !== -1) {
        op = 'gte';
      }
      if (id.search('max-') !== -1) {
        op = 'lte';
      }
      result.push({
        id: id,
        value: value,
        op: op
      });
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return result;
};

// takes in an array of 'id, value' objects that react-table uses for it's 'filtered' prop and converts it to an object of id's as keys and values as values.
// ex. [{ id: 'account', value: 'some-account-label' }] -> { account: 'some-account-label' }
exports.paramsToObject = paramsToObject;
var filteredArrayToObject = function filteredArrayToObject(filteredArray) {
  if (!Array.isArray(filteredArray)) {
    console.warn('Bad argument passed to filteredArrayToObject');
    return {};
  }
  return filteredArray.reduce(function (acc, val) {
    return _objectSpread(_objectSpread({}, acc), val.id && (0, _defineProperty2.default)({}, val.id, val.value));
  }, {});
};
exports.filteredArrayToObject = filteredArrayToObject;
var filteredArrayToAttrValue = function filteredArrayToAttrValue(filteredArray) {
  if (!Array.isArray(filteredArray)) {
    console.warn('Bad argument passed to filteredArrayToAttrValue');
    return [];
  }
  return filteredArray.filter(function (_ref2) {
    var value = _ref2.value;
    return (
      // filter out the empty values and remove from resulting array
      !(0, _lodash.isEmpty)(value)
    );
  }).map(function (_ref3) {
    var id = _ref3.id,
      value = _ref3.value,
      op = _ref3.op;
    // filter out the min- max- prefixes since we can't use them in the backend.
    var attr = id.replace(/((min-)|(max-))/g, '');
    return {
      attr: attr,
      value: value,
      op: op
    };
  });
};
exports.filteredArrayToAttrValue = filteredArrayToAttrValue;
var filteredArrayToAttrFalsyValue = function filteredArrayToAttrFalsyValue(filteredArray) {
  if (!Array.isArray(filteredArray)) {
    console.warn('Bad argument passed to filteredArrayToAttrValue');
    return [];
  }
  return filteredArray.filter(function (_ref4) {
    var value = _ref4.value;
    return value !== undefined;
  }).map(function (_ref5) {
    var id = _ref5.id,
      value = _ref5.value,
      op = _ref5.op;
    // filter out the min- max- prefixes since we can't use them in the backend.
    var attr = id.replace(/((min-)|(max-))/g, '');
    return {
      attr: attr,
      value: value,
      op: op
    };
  });
};
exports.filteredArrayToAttrFalsyValue = filteredArrayToAttrFalsyValue;
var sortedArrayToAttrValue = function sortedArrayToAttrValue(sortedArray) {
  if (!Array.isArray(sortedArray)) {
    console.warn('Bad argument passed to sortedArrayToAttrValue');
    return [];
  }
  return sortedArray.map(function (_ref6) {
    var _ref6$id = _ref6.id,
      id = _ref6$id === void 0 ? '' : _ref6$id,
      desc = _ref6.desc;
    return {
      attr: (0, _changeCaseObject.snakeCase)(id),
      value: desc ? 'desc' : 'asc'
    };
  });
};
exports.sortedArrayToAttrValue = sortedArrayToAttrValue;
var inputOnChange = function inputOnChange(onChange) {
  return function (e, _ref7) {
    var name = _ref7.name,
      value = _ref7.value;
    return onChange(name, value, _filterOps.default[name] || 'match');
  };
};
exports.inputOnChange = inputOnChange;
var checkboxOnChange = function checkboxOnChange(onChange) {
  return function (e, _ref8) {
    var name = _ref8.name,
      _ref8$checked = _ref8.checked,
      checked = _ref8$checked === void 0 ? false : _ref8$checked;
    if (checked) {
      onChange(name, checked.toString(), 'eq');
    } else {
      onChange(name, '');
    }
  };
};
exports.checkboxOnChange = checkboxOnChange;
var datetimeOnchange = function datetimeOnchange(name, op) {
  return function (onChange) {
    return function (time) {
      var filter = time;
      // have to format the time if it's a moment object.
      // when the user selects from the date-time dropdown, you have to format it, but if the user edits
      // the input inline, it's a normal string.
      if (_moment.default.isMoment(time)) {
        filter = time.format();
      }
      onChange(name, filter, op);
    };
  };
};
exports.datetimeOnchange = datetimeOnchange;