export default {
  RISK_ALERTS_THRESHOLDS: 'risk_alerts_thresholds',
  STRESS_ENGINE_PARAMS: 'stress_engine_parameters',
  DFRR_SIZING_PARAMS: 'dfrr_sizing_parameters',
  GUARANTY_FUND_ALLOCATION_PARAMS: 'guarantee_fund_allocation_parameters',
  MARGIN_RATES_MULTIPLIERS: 'margin_rates_multipliers',
  RISK_ADJUSTMENTS: 'risk_adjustments',
  CONCENTRATION_ADD_ON: 'concentration_add_on',
  SPAN: 'span_spread_priority',
  PRICING_ENGINE_PARAMS: 'pricing_engine_parameters',
  LARGE_TRADER_MONITORING: 'large_trader_monitoring',
  LARGE_TRADER_REPORT_FIRM_MAPPING: 'large_trader_report_firm_mapping',
  FINANCIAL_REVIEW_METRICS: 'financial_review_metrics',
  WINJAMMER_ACCT_MAP: 'winjammer_acct_map',
  NSE_THRESHOLD_CONFIG: 'nse_threshold_config',
  CLEARING_MEMBER_GROUP: 'clearing_member_group',
  SPAN_INTER_SPREAD: 'span_inter_spread_priority',
  MARGIN_PROFILES: 'margin_profiles',
  RISK_PROFILES: 'risk_profiles',
};
